import StatsContent from "../components/stats";
const Stats = () => {

  return (
    <>
      <StatsContent />
    </>
  );
};

export default Stats;
