import React, { useEffect, useState } from "react";
import RewardContentStyleWrapper from "./RewardPoolContent.style";
import ProgressBar from "@ramonak/react-progress-bar";
import RewardPoolPayout from "../../../common/rewardspoolpayout/RewardPoolPayout";
import DashboardText from "../../../common/dashboardtext/DashboardText";
import tooltipText from "../../../assets/helius/tooltipText.json";
import heliosContract from "../../../contract/helios.json";
import treasuryContract from "../../../contract/treasury.json";
import titanxContract from "../../../contract/titanx.json";
import wplsPool from "../../../contract/wplsPool.json";

import {
  useAccount,
  useReadContract,
  useReadContracts,
  useWriteContract,
  useWaitForTransactionReceipt,
  useBalance,
  useSwitchChain,
  useChainId,
} from "wagmi";
import { useSelector } from "react-redux";
import {
  TreasuryAddress,
  HeliosAddress,
  etherToFixed,
  TitanxAddress,
  InvestmentPoolAddressPls,
  BunsAddress,
  AliceAddress,
  HatterAddress,
  CheshireAddress,
  QueenAdress,
  BunsTreasuryAddress,
  BunsPlsPoolAddress,
  AlicePlsPoolAddress,
  HatterPlsPoolAddress,
  CheshirePlsPoolAddress,
  QueenPlsPoolAddress,
  wpls,
} from "../../../const/const";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "../../../common/tooltip";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import MaxStakeTooltip from "../../modal/MaxStakeTooltip";
import ClaimDistTooltip from "../../modal/ClaimDistTooltip";
import DistTinethTooltip from "../../modal/DistTinethTooltip";
import DistPayoutTooltip from "../../modal/DistPayoutTooltip";
import { ethers } from "ethers";
import { pulsechain, mainnet } from "wagmi/chains";

const RewardPoolContent = () => {
  const { address, isConnected } = useAccount();

  // Start Get Redux store variables
  const ether_to_usd = useSelector((state) => state.price.ether_to_usd); // Fetch the value of ether price from redux
  const titanx_to_usd = useSelector((state) => state.price.titanx_to_usd); // Fetch the value of TitanX price from redux
  const pls_to_usd = useSelector((state) => state.price.pls_to_usd);
  const { switchChain } = useSwitchChain();
  const chainId = useChainId();

  const [ethCycle22Payout, setEthCycle22Payout] = useState(0);
  const [ethCycle69Payout, setEthCycle69Payout] = useState(0);
  const [ethCycle420Payout, setEthCycle420Payout] = useState(0);
  const [cycle22Payout, setCycle22Payout] = useState(0);
  const [cycle69Payout, setCycle69Payout] = useState(0);
  const [cycle420Payout, setCycle420Payout] = useState(0);
  const [userEthCycle22Payout, setUserEthCycle22Payout] = useState(0);
  const [userEthCycle69Payout, setUserEthCycle69Payout] = useState(0);
  const [userEthCycle420Payout, setUserEthCycle420Payout] = useState(0);
  const [userCycle22Payout, setUserCycle22Payout] = useState(0);
  const [userCycle69Payout, setUserCycle69Payout] = useState(0);
  const [userCycle420Payout, setUserCycle420Payout] = useState(0);
  const [liquidTitanX, setLiquidTitanX] = useState(0);
  const [titanxStaked, setTitanxStaked] = useState(0);
  const [claimableETH, setClaimableETH] = useState(0);
  const [investmentBalance, setInvestimentBalance] = useState(0);
  const [investmentPlsBalance, setInvestimentPlsBalance] = useState(0);
  const [bunsTreasuryPlsBalance, setBunsTreasuryPlsBalance] = useState(0);
  const [bunsTreasuryBunsBalance, setBunsTreasuryBunsBalance] = useState(0);
  const [bunsTreasuryAliceBalance, setBunsTreasuryAliceBalance] = useState(0);
  const [bunsTreasuryHatterBalance, setBunsTreasuryHatterBalance] = useState(0);
  const [bunsTreasuryCheshireBalance, setBunsTreasuryCheshireBalance] =
    useState(0);
  const [bunsTreasuryQueenBalance, setBunsTreasuryQueenBalance] = useState(0);

  const [activeHlxStakeContract, setActiveHlxStakeContract] =
    useState(TreasuryAddress);
  const [disableButton, setDisableButton] = useState(false);

  const [bunsUsdtPrice, setBunsUsdtPrice] = useState(0);
  const [aliceUsdtPrice, setAliceUsdtPrice] = useState(0);
  const [hatterUsdtPrice, setHatterUsdtPrice] = useState(0);
  const [cheshireUsdtPrice, setCheshireUsdtPrice] = useState(0);
  const [queenUsdtPrice, setQueenUsdtPrice] = useState(0);

  useEffect(() => {
    if (chainId && chainId !== 1) {
      switchChain({ chainId: 1 });
    }
  }, [chainId, switchChain]);

  const heliosObj = {
    address: HeliosAddress,
    abi: heliosContract.abi,
  };
  const treasuryObj = {
    address: TreasuryAddress,
    abi: treasuryContract.abi,
  };
  const titanxObj = {
    address: TitanxAddress,
    abi: titanxContract.abi,
  };

  const { data: userEst } = useReadContracts({
    contracts: [
      {
        ...heliosObj,
        functionName: "getGlobalActiveShares",
      },
      {
        ...heliosObj,
        functionName: "getUserCurrentActiveShares",
        args: [address],
      },
    ],
    watch: true,
  });

  const { data: bunsPoolData } = useReadContracts({
    contracts: [
      {
        address: BunsPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        address: BunsPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "getReserves",
        chainId: pulsechain.id,
      },
    ],
  });

  const { data: alicePoolData } = useReadContracts({
    contracts: [
      {
        address: AlicePlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        address: AlicePlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "getReserves",
        chainId: pulsechain.id,
      },
    ],
  });

  const { data: hatterPoolData } = useReadContracts({
    contracts: [
      {
        address: HatterPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        address: HatterPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "getReserves",
        chainId: pulsechain.id,
      },
    ],
  });

  const { data: cheshirePoolData } = useReadContracts({
    contracts: [
      {
        address: CheshirePlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        address: CheshirePlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "getReserves",
        chainId: pulsechain.id,
      },
    ],
  });

  const { data: queensPoolData } = useReadContracts({
    contracts: [
      {
        address: QueenPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        address: QueenPlsPoolAddress,
        abi: wplsPool.abi,
        functionName: "getReserves",
        chainId: pulsechain.id,
      },
    ],
  });

  useEffect(() => {
    if (bunsPoolData && bunsPoolData[0].status === "success") {
      const token0 = bunsPoolData[0].result;
      if (token0 === BunsAddress) {
        const BunsPriceInPLS =
          parseFloat(bunsPoolData[1].result[1].toString()) /
          parseFloat(bunsPoolData[1].result[0].toString());
        if (pls_to_usd !== 0) {
          setBunsUsdtPrice(
            (
              parseFloat(BunsPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      } else {
        const BunsPriceInPLS =
          parseFloat(bunsPoolData[1].result[0].toString()) /
          parseFloat(bunsPoolData[1].result[1].toString());

        if (pls_to_usd !== 0) {
          setBunsUsdtPrice(
            (
              parseFloat(BunsPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      }
    }
  }, [bunsPoolData, pls_to_usd]);

  useEffect(() => {
    if (alicePoolData && alicePoolData[0].status === "success") {
      const token0 = alicePoolData[0].result;
      if (token0 === AliceAddress) {
        const AlicePriceInPLS =
          parseFloat(alicePoolData[1].result[1].toString()) /
          parseFloat(alicePoolData[1].result[0].toString());
        if (pls_to_usd !== 0) {
          setAliceUsdtPrice(
            (
              parseFloat(AlicePriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      } else {
        const AlicePriceInPLS =
          parseFloat(alicePoolData[1].result[0].toString()) /
          parseFloat(alicePoolData[1].result[1].toString());

        if (pls_to_usd !== 0) {
          setAliceUsdtPrice(
            (
              parseFloat(AlicePriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      }
    }
  }, [alicePoolData, pls_to_usd]);

  useEffect(() => {
    if (hatterPoolData && hatterPoolData[0].status === "success") {
      const token0 = hatterPoolData[0].result;
      if (token0 === HatterAddress) {
        const HatterPriceInPLS =
          parseFloat(hatterPoolData[1].result[1].toString()) /
          parseFloat(hatterPoolData[1].result[0].toString());
        if (pls_to_usd !== 0) {
          setHatterUsdtPrice(
            (
              parseFloat(HatterPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      } else {
        const HatterPriceInPLS =
          parseFloat(hatterPoolData[1].result[0].toString()) /
          parseFloat(hatterPoolData[1].result[1].toString());

        if (pls_to_usd !== 0) {
          setHatterUsdtPrice(
            (
              parseFloat(HatterPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      }
    }
  }, [hatterPoolData, pls_to_usd]);

  useEffect(() => {
    if (cheshirePoolData && cheshirePoolData[0].status === "success") {
      const token0 = cheshirePoolData[0].result;
      if (token0 === CheshireAddress) {
        const CheshirePriceInPLS =
          parseFloat(cheshirePoolData[1].result[1].toString()) /
          parseFloat(cheshirePoolData[1].result[0].toString());
        if (pls_to_usd !== 0) {
          setCheshireUsdtPrice(
            (
              parseFloat(CheshirePriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      } else {
        const CheshirePriceInPLS =
          parseFloat(cheshirePoolData[1].result[0].toString()) /
          parseFloat(cheshirePoolData[1].result[1].toString());

        if (pls_to_usd !== 0) {
          setCheshireUsdtPrice(
            (
              parseFloat(CheshirePriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      }
    }
  }, [cheshirePoolData, pls_to_usd]);

  useEffect(() => {
    if (queensPoolData && queensPoolData[0].status === "success") {
      const token0 = queensPoolData[0].result;
      if (token0 === QueenAdress) {
        const QueensPriceInPLS =
          parseFloat(queensPoolData[1].result[1].toString()) /
          parseFloat(queensPoolData[1].result[0].toString());
        if (pls_to_usd !== 0) {
          setQueenUsdtPrice(
            (
              parseFloat(QueensPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      } else {
        const QueensPriceInPLS =
          parseFloat(queensPoolData[1].result[0].toString()) /
          parseFloat(queensPoolData[1].result[1].toString());

        if (pls_to_usd !== 0) {
          setQueenUsdtPrice(
            (
              parseFloat(QueensPriceInPLS.toString()) * parseFloat(pls_to_usd)
            ).toFixed(18)
          );
        }
      }
    }
  }, [queensPoolData, pls_to_usd]);

  const { data: treasuryReads } = useReadContracts({
    contracts: [
      {
        address: BunsAddress,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [BunsTreasuryAddress],
        chainId: pulsechain.id,
      },
      {
        address: AliceAddress,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [BunsTreasuryAddress],
        chainId: pulsechain.id,
      },
      {
        address: HatterAddress,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [BunsTreasuryAddress],
        chainId: pulsechain.id,
      },
      {
        address: CheshireAddress,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [BunsTreasuryAddress],
        chainId: pulsechain.id,
      },
      {
        address: QueenAdress,
        abi: heliosContract.abi,
        functionName: "balanceOf",
        args: [BunsTreasuryAddress],
        chainId: pulsechain.id,
      },
    ],
    watch: true,
  });

  useEffect(() => {
    if (treasuryReads && treasuryReads[0].status === "success") {
      setBunsTreasuryBunsBalance(
        treasuryReads[0]
          ? treasuryReads[0]?.status === "success"
            ? treasuryReads[0]?.result?.toString()
            : 0
          : 0
      );
      setBunsTreasuryAliceBalance(
        treasuryReads[1]
          ? treasuryReads[1]?.status === "success"
            ? treasuryReads[1]?.result?.toString()
            : 0
          : 0
      );

      setBunsTreasuryHatterBalance(
        treasuryReads[2]
          ? treasuryReads[2]?.status === "success"
            ? treasuryReads[2]?.result?.toString()
            : 0
          : 0
      );

      setBunsTreasuryCheshireBalance(
        treasuryReads[3]
          ? treasuryReads[3]?.status === "success"
            ? treasuryReads[3]?.result?.toString()
            : 0
          : 0
      );

      setBunsTreasuryQueenBalance(
        treasuryReads[4]
          ? treasuryReads[4]?.status === "success"
            ? treasuryReads[4]?.result?.toString()
            : 0
          : 0
      );
    }
  }, [treasuryReads]);

  const { data } = useReadContracts({
    contracts: [
      {
        ...heliosObj,
        functionName: "getGlobalActiveShares",
      },
      {
        ...heliosObj,
        functionName: "getETHCyclePayoutPool",
        args: [22],
      },
      {
        ...heliosObj,
        functionName: "getETHCyclePayoutPool",
        args: [69],
      },
      {
        ...heliosObj,
        functionName: "getETHCyclePayoutPool",
        args: [420],
      },
      {
        ...heliosObj,
        functionName: "getCyclePayoutPool",
        args: [22],
      },
      {
        ...heliosObj,
        functionName: "getCyclePayoutPool",
        args: [69],
      },
      {
        ...heliosObj,
        functionName: "getCyclePayoutPool",
        args: [420],
      },
      {
        ...heliosObj,
        functionName: "calculateUserCycleReward",
        args: [address, 22],
      },
      {
        ...heliosObj,
        functionName: "calculateUserCycleReward",
        args: [address, 69],
      },
      {
        ...heliosObj,
        functionName: "calculateUserCycleReward",
        args: [address, 420],
      },
      {
        ...heliosObj,
        functionName: "getUndistributedTitanX",
      },
      {
        ...heliosObj,
        functionName: "getUndistributedETH",
      },
    ],
    watch: true,
  });

  const { data: treasuryData } = useReadContracts({
    contracts: [
      {
        ...treasuryObj,
        functionName: "getTitanBalance",
      },
      {
        ...treasuryObj,
        functionName: "getTotalTitanStaked",
      },
      {
        ...treasuryObj,
        functionName: "getTotalTitanUnstaked",
      },
      {
        ...titanxObj,
        functionName: "getUserETHClaimableTotal",
        args: [activeHlxStakeContract],
      },
      {
        ...treasuryObj,
        functionName: "activeHlxStakeContract",
      },
    ],
    watch: true,
  });

  /**
   *@description: Distribute payout
   */
  const {
    data: distributePayoutData,
    error: distributePayoutError,
    writeContract: distributePayout,
    isLoading: isDistributePayoutLoading,
  } = useWriteContract();

  const { isSuccess: isDistributePayoutConfirmed } =
    useWaitForTransactionReceipt({
      hash: distributePayoutData,
    });

  useEffect(() => {
    if (distributePayoutError) {
      toast.error(distributePayoutError.message.slice(0, 500) + "...", {
        autoClose: 5000,
      });
    }
  }, [distributePayoutError]);

  useEffect(() => {
    if (isDistributePayoutConfirmed) {
      toast.success("Distributed Successfully", {
        autoClose: 5000,
      });
    }
  }, [isDistributePayoutConfirmed]);

  const distributePayoutClicked = async () => {
    distributePayout({
      address: HeliosAddress,
      abi: heliosContract.abi,
      functionName: "triggerPayouts",
      args: [],
    });
  };

  /**
   *@description: Distribute TitanX
   */
  const {
    data: distributeTitanxData,
    writeContract: distributeTitanx,
    error: distributeTitanxError,
    isLoading: isdistributeTitanxLoading,
  } = useWriteContract();

  const { isSuccess: isDistributeTitanxConfirmed } =
    useWaitForTransactionReceipt({
      hash: distributeTitanxData,
    });

  useEffect(() => {
    if (distributeTitanxError) {
      toast.error(distributeTitanxError.message.slice(0, 500) + "...", {
        autoClose: 5000,
      });
    }
  }, [distributeTitanxError]);

  useEffect(() => {
    if (isDistributeTitanxConfirmed) {
      toast.success("Distributed Successfully", {
        autoClose: 5000,
      });
    }
  }, [isDistributeTitanxConfirmed]);

  const distributeTitanxClicked = async () => {
    distributeTitanx({
      address: HeliosAddress,
      abi: heliosContract.abi,
      functionName: "distributeTitanX",
      args: [],
    });
  };

  /**
   *@description: Max Stake button stakeTITANX()
   */
  const {
    data: maxStakeData,
    writeContract: maxStake,
    error: maxStakeError,
    isLoading: isMaxStakeLoading,
  } = useWriteContract();

  const maxStakeClicked = async () => {
    maxStake({
      address: TreasuryAddress,
      abi: treasuryContract.abi,
      functionName: "stakeTITANX",
      args: [],
    });
  };

  const { isSuccess: isMaxStakeConfirmed } = useWaitForTransactionReceipt({
    hash: maxStakeData,
  });

  useEffect(() => {
    if (maxStakeError) {
      toast.error(
        "Minimum wait time of 7 days or minimum 100B TITANX balance required",
        {
          autoClose: 5000,
        }
      );
    }
  }, [maxStakeError]);

  useEffect(() => {
    if (isMaxStakeConfirmed) {
      toast.success("Staked Successfully", {
        autoClose: 5000,
      });
    }
  }, [isMaxStakeConfirmed]);

  /**
   *@description: Claim Distribute -> claimReward()
   */
  const {
    data: claimDistributeData,
    writeContract: claimDistribute,
    error: claimDistributeError,
    isLoading: isClaimDistributeLoading,
  } = useWriteContract();

  const { isSuccess: isClaimDistributeConfirmed } =
    useWaitForTransactionReceipt({
      hash: claimDistributeData,
    });

  useEffect(() => {
    if (claimDistributeError) {
      toast.error("There is no ETH to claim", {
        autoClose: 5000,
      });
    }
  }, [claimDistributeError]);

  useEffect(() => {
    if (isClaimDistributeConfirmed) {
      toast.success("Claim Distributed Successfully", {
        autoClose: 5000,
      });
    }
  }, [isClaimDistributeConfirmed]);

  const claimDistributeClicked = async () => {
    claimDistribute({
      address: TreasuryAddress,
      abi: treasuryContract.abi,
      functionName: "claimReward",
      args: [],
    });
  };

  // Get getCurrentContractDay
  const { data: currentContractDay } = useReadContract({
    address: HeliosAddress,
    abi: heliosContract.abi,
    functionName: "getCurrentContractDay",
    watch: true,
  });
  /**
   *@description: getCurrentBlockTimeStamp
   */
  const { data: cur_blockTimestamp } = useReadContract({
    address: HeliosAddress,
    abi: heliosContract.abi,
    functionName: "getCurrentBlockTimeStamp",
    watch: true,
  });
  /**
   *@description genesisTs
   */
  const { data: genesisTs } = useReadContract({
    address: HeliosAddress,
    abi: heliosContract.abi,
    functionName: "genesisTs",
    watch: true,
  });

  const { data: investaddress } = useReadContract({
    address: TreasuryAddress,
    abi: treasuryContract.abi,
    functionName: "getInvestmentPoolAddress",
    watch: true,
  });
  const [getTwoProgress, setGetTwoprogress] = useState();
  const [getSixProgress, setGetSixprogress] = useState();
  const [getFourProgress, setGetFourprogress] = useState();

  function calculatePoolCompletionPercentage(
    currentContractDay,
    payoutCycleLength
  ) {
    // Calculate the number of days into the current payout cycle
    const daysIntoCurrentCycle = currentContractDay % payoutCycleLength;

    // Calculate the percentage of the cycle completed
    const percentageOfCycleCompleted =
      (daysIntoCurrentCycle / payoutCycleLength) * 100;

    return percentageOfCycleCompleted;
  }
  const [userTitanx22, setuserTitanx22] = useState(0);
  const [userTitanx69, setuserTitanx69] = useState(0);
  const [userTitanx420, setuserTitanx420] = useState(0);
  const [userEther22, setuserEther22] = useState(0);
  const [userEther69, setuserEther69] = useState(0);
  const [userEther420, setuserEther420] = useState(0);
  const [userUsd22, setuserUsd22] = useState(0);
  const [userUsd69, setuserUsd69] = useState(0);
  const [userUsd420, setuserUsd420] = useState(0);

  const { data: userBalance } = useBalance({
    address: investaddress ? investaddress : ethers.constants.AddressZero,
    chainId: mainnet.id,
  });

  const { data: userPlsBalance } = useBalance({
    address: InvestmentPoolAddressPls,
    chainId: pulsechain.id,
  });

  const { data: userBunsTreasuryPLSBalance } = useBalance({
    address: BunsTreasuryAddress,
    chainId: pulsechain.id,
  });

  useEffect(() => {
    if (data && data[1].status === "success") {
      setEthCycle22Payout(data[1] ? data[1]?.result?.toString() || 0 : 0);
      setEthCycle69Payout(data[2] ? data[2]?.result?.toString() || 0 : 0);
      setEthCycle420Payout(data[3] ? data[3]?.result?.toString() || 0 : 0);
      setCycle22Payout(data[4] ? data[4]?.result?.toString() || 0 : 0);
      setCycle69Payout(data[5] ? data[5]?.result?.toString() || 0 : 0);
      setCycle420Payout(data[6] ? data[6]?.result?.toString() || 0 : 0);
      setUserEthCycle22Payout(
        data[7].status !== "failure" ? data[7]?.result[1]?.toString() || 0 : 0
      );
      setUserEthCycle69Payout(
        data[8].status !== "failure" ? data[8]?.result[1]?.toString() || 0 : 0
      );
      setUserEthCycle420Payout(
        data[9].status !== "failure" ? data[9]?.result[1]?.toString() || 0 : 0
      );
      setUserCycle22Payout(
        data[7].status !== "failure" ? data[7]?.result[0]?.toString() || 0 : 0
      );
      setUserCycle69Payout(
        data[8].status !== "failure" ? data[8]?.result[0]?.toString() || 0 : 0
      );
      setUserCycle420Payout(
        data[9].status !== "failure" ? data[9]?.result[0]?.toString() || 0 : 0
      );
      if (
        (data[11] ? data[11]?.result?.toString() || 0 : 0) === 0 &&
        (data[10] ? data[10]?.result?.toString() || 0 : 0) === 0
      ) {
        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
      if (userEst && userEst[0].status === "success") {
        if (parseFloat(userEst[0]?.result || 0) !== 0) {
          const userEstRate =
            (userEst[1] ? userEst[1]?.result?.toString() || 0 : 0) /
            (userEst[0] ? userEst[0]?.result?.toString() || 0 : 0);
          setuserTitanx22(
            etherToFixed(data[4] ? data[4]?.result || 0 : 0) * userEstRate
          );
          setuserTitanx69(
            etherToFixed(data[5] ? data[5]?.result || 0 : 0) * userEstRate
          );
          setuserTitanx420(
            etherToFixed(data[6] ? data[6]?.result || 0 : 0) * userEstRate
          );
          setuserEther22(
            etherToFixed(data[1] ? data[1]?.result || 0 : 0) * userEstRate
          );
          setuserEther69(
            etherToFixed(data[2] ? data[2]?.result || 0 : 0) * userEstRate
          );
          setuserEther420(
            etherToFixed(data[3] ? data[3]?.result || 0 : 0) * userEstRate
          );

          setuserUsd22(
            etherToFixed(data[4] ? data[4]?.result || 0 : 0) *
              userEstRate *
              titanx_to_usd +
              etherToFixed(data[1] ? data[1]?.result || 0 : 0) *
                userEstRate *
                ether_to_usd
          );
          setuserUsd69(
            etherToFixed(data[5] ? data[5]?.result || 0 : 0) *
              userEstRate *
              titanx_to_usd +
              etherToFixed(data[2] ? data[2]?.result || 0 : 0) *
                userEstRate *
                ether_to_usd
          );
          setuserUsd420(
            etherToFixed(data[6] ? data[6]?.result || 0 : 0) *
              userEstRate *
              titanx_to_usd +
              etherToFixed(data[3] ? data[3]?.result || 0 : 0) *
                userEstRate *
                ether_to_usd
          );
        }
      }
    }
    if (userBalance) {
      setInvestimentBalance(userBalance ? userBalance.value : 0);
    }
    if (userPlsBalance) {
      setInvestimentPlsBalance(userPlsBalance ? userPlsBalance.value : 0);
    }
    if (userBunsTreasuryPLSBalance) {
      setBunsTreasuryPlsBalance(
        userBunsTreasuryPLSBalance ? userBunsTreasuryPLSBalance.value : 0
      );
    }
    setGetTwoprogress(
      calculatePoolCompletionPercentage(
        currentContractDay ? parseInt(currentContractDay.toString()) : 0,
        22
      )
    );
    setGetSixprogress(
      calculatePoolCompletionPercentage(
        currentContractDay ? parseInt(currentContractDay.toString()) : 0,
        69
      )
    );
    setGetFourprogress(
      calculatePoolCompletionPercentage(
        currentContractDay ? parseInt(currentContractDay.toString()) : 0,
        420
      )
    );

    if (treasuryData && treasuryData[0].status === "success") {
      setLiquidTitanX(
        treasuryData[0] ? treasuryData[0]?.result?.toString() || 0 : 0
      );
      setTitanxStaked(
        treasuryData[1]
          ? treasuryData[2]
            ? (
                parseFloat(etherToFixed(treasuryData[1]?.result || 0)) -
                parseFloat(etherToFixed(treasuryData[2]?.result || 0))
              )?.toString()
            : 0
          : 0
      );
      setActiveHlxStakeContract(
        treasuryData[4] ? treasuryData[4]?.result?.toString() || 0 : 0
      );
    }
  }, [data, treasuryData, disableButton, userBalance]);

  useEffect(() => {
    if (treasuryData && treasuryData[3].status === "success") {
      setClaimableETH(
        treasuryData[3] ? treasuryData[3]?.result?.toString() || 0 : 0
      );
    }
  }, [treasuryData]);

  const heliosContractObj = {
    address: HeliosAddress,
    abi: heliosContract.abi,
  };
  const { data: datawithcount } = useReadContracts({
    contracts: [
      {
        ...heliosContractObj,
        functionName: "getNextCyclePayoutDay",
        args: [22],
      },
      {
        ...heliosContractObj,
        functionName: "getNextCyclePayoutDay",
        args: [69],
      },
      {
        ...heliosContractObj,
        functionName: "getNextCyclePayoutDay",
        args: [420],
      },
    ],
    watch: true,
  });
  const [twoNextPayoutDay, setTwoNextPayoutDay] = useState(0);
  const [fourNextPayoutDay, setFourNextPayoutDay] = useState(0);
  const [sixNextPayoutDay, setSixNextPayoutDay] = useState(0);
  const [twoCountDown, setTwoCountDown] = useState("");
  const [sixCountDown, setSixCountDown] = useState("");
  const [fourCountDown, setFourCountDown] = useState("");
  useEffect(() => {
    if (datawithcount && datawithcount[0].status === "success") {
      setTwoNextPayoutDay(datawithcount[0]?.result?.toString());
      setSixNextPayoutDay(datawithcount[1]?.result?.toString());
      setFourNextPayoutDay(datawithcount[2]?.result?.toString());
      const two_next_day = datawithcount[0]?.result?.toString();
      const six_next_day = datawithcount[1]?.result?.toString();
      const four_next_day = datawithcount[2]?.result?.toString();
      const genesisTs_timestamp = genesisTs?.toString();
      const current_timestamp = cur_blockTimestamp?.toString();

      const calculateTimeDifference = (
        timestamp,
        currentTimestamp,
        genesisTs
      ) => {
        const timestampSeconds = timestamp * 86400; // Convert timestamp to seconds
        const timeDifferenceSeconds =
          timestampSeconds - (currentTimestamp - genesisTs) - 86400; // Calculate difference in seconds
        const days = Math.floor(timeDifferenceSeconds / 86400); // Calculate number of days
        const remainingSeconds = timeDifferenceSeconds % 86400; // Calculate remaining seconds after subtracting days
        const date = new Date(remainingSeconds * 1000); // Convert remaining seconds to date
        const hours = date.getUTCHours().toString().padStart(2, "0");
        const minutes = date.getUTCMinutes().toString().padStart(2, "0");
        const seconds = date.getUTCSeconds().toString().padStart(2, "0");
        return `${days}D:${hours}H:${minutes}M:${seconds}S`;
      };

      const two_next_day_formatted = calculateTimeDifference(
        two_next_day,
        current_timestamp,
        genesisTs_timestamp
      );
      const six_next_day_formatted = calculateTimeDifference(
        six_next_day,
        current_timestamp,
        genesisTs_timestamp
      );
      const four_next_day_formatted = calculateTimeDifference(
        four_next_day,
        current_timestamp,
        genesisTs_timestamp
      );
      setTwoCountDown(two_next_day_formatted);
      setSixCountDown(six_next_day_formatted);
      setFourCountDown(four_next_day_formatted);
    }
  }, [datawithcount, cur_blockTimestamp, genesisTs]);
  return (
    <RewardContentStyleWrapper>
      <div className="reward_container">
        <div className="ether_subtitle">
          <h2 className="assets_under_management">Assets Under Management </h2>
        </div>
        <div className="rows">
          <div className="cols">
            <div className="reward_pool_cards">
              <h1 className="cycle_reward_pool">Cycle Reward Pools</h1>
              <div className="cards_contents">
                <h3>22 DAY</h3>
                <div className="card_content_rewards">
                  <RewardPoolPayout
                    name="Global Payout"
                    titanx={etherToFixed(
                      cycle22Payout ? cycle22Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    ether={etherToFixed(
                      ethCycle22Payout ? ethCycle22Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                    usd={(
                      parseFloat(
                        etherToFixed(ethCycle22Payout ? ethCycle22Payout : 0)
                      ) *
                        ether_to_usd +
                      parseFloat(etherToFixed(cycle22Payout)) * titanx_to_usd
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "GlobalPayout" in asset
                      )?.GlobalPayout
                    }
                  />
                  <RewardPoolPayout
                    name="Your Est. Payout"
                    type="rewardpool"
                    titanx={(userTitanx22 ? userTitanx22 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                    )}
                    ether={(userEther22 ? userEther22 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 4, maximumFractionDigits: 4 }
                    )}
                    usd={(userUsd22 ? userUsd22 : 0).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "yourestpayout" in asset
                      )?.yourestpayout
                    }
                  />
                </div>
                <div className="progress_content">
                  <ProgressBar
                    className="progress_bar"
                    completed={
                      getTwoProgress ? parseFloat(getTwoProgress.toFixed(0)) : 0
                    }
                    bgColor="linear-gradient(90deg, rgba(254,156,1,1) 13%, rgba(186,53,5,1) 87%)"
                    isLabelVisible={true}
                  />
                  <Tooltip
                    text={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "progressbar" in asset
                      )?.progressbar
                    }
                  >
                    <AiOutlineQuestionCircle />
                  </Tooltip>
                </div>
                <div className="nextpayoutday_countdown">
                  <div className="nextpayoutday">
                    <div className="nextpayoutday_title">
                      <span>Next Payout Day:</span>
                      <span className="number_next_day">
                        {" "}
                        {twoNextPayoutDay}
                      </span>
                    </div>
                    <div className="countdown_value">{twoCountDown}</div>
                  </div>
                </div>
              </div>
              <div className="cards_contents">
                <h3>69 DAY</h3>
                <div className="card_content_rewards">
                  <RewardPoolPayout
                    name="Global Payout"
                    titanx={etherToFixed(
                      cycle69Payout ? cycle69Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    ether={etherToFixed(
                      ethCycle69Payout ? ethCycle69Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                    usd={(
                      parseFloat(
                        etherToFixed(ethCycle69Payout ? ethCycle69Payout : 0)
                      ) *
                        ether_to_usd +
                      parseFloat(etherToFixed(cycle69Payout)) * titanx_to_usd
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "GlobalPayout" in asset
                      )?.GlobalPayout
                    }
                  />
                  <RewardPoolPayout
                    name="Your Est. Payout"
                    type="rewardpool"
                    titanx={(userTitanx69 ? userTitanx69 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                    )}
                    ether={(userEther69 ? userEther69 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 4, maximumFractionDigits: 4 }
                    )}
                    usd={(userUsd69 ? userUsd69 : 0).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "yourestpayout" in asset
                      )?.yourestpayout
                    }
                  />
                </div>
                <div className="progress_content">
                  <ProgressBar
                    className="progress_bar"
                    completed={
                      getSixProgress ? parseFloat(getSixProgress.toFixed(0)) : 0
                    }
                    bgColor="linear-gradient(90deg, rgba(254,156,1,1) 13%, rgba(186,53,5,1) 87%)"
                    isLabelVisible={true}
                  />
                  <Tooltip
                    text={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "progressbar" in asset
                      )?.progressbar
                    }
                  >
                    <AiOutlineQuestionCircle />
                  </Tooltip>
                </div>
                <div className="nextpayoutday_countdown">
                  <div className="nextpayoutday">
                    <div className="nextpayoutday_title">
                      <span>Next Payout Day:</span>
                      <span className="number_next_day">
                        {" "}
                        {sixNextPayoutDay}
                      </span>
                    </div>
                    <div className="countdown_value">{sixCountDown}</div>
                  </div>
                </div>
              </div>
              <div className="cards_contents">
                <h3>420 DAY</h3>
                <div className="card_content_rewards">
                  <RewardPoolPayout
                    name="Global Payout"
                    titanx={etherToFixed(
                      cycle420Payout ? cycle420Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    ether={etherToFixed(
                      ethCycle420Payout ? ethCycle420Payout : 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                    usd={(
                      parseFloat(
                        etherToFixed(ethCycle420Payout ? ethCycle420Payout : 0)
                      ) *
                        ether_to_usd +
                      parseFloat(etherToFixed(cycle420Payout)) * titanx_to_usd
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "GlobalPayout" in asset
                      )?.GlobalPayout
                    }
                  />
                  <RewardPoolPayout
                    name="Your Est. Payout"
                    type="rewardpool"
                    titanx={(userTitanx420 ? userTitanx420 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 0, maximumFractionDigits: 0 }
                    )}
                    ether={(userEther420 ? userEther420 : 0).toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 4, maximumFractionDigits: 4 }
                    )}
                    usd={(userUsd420 ? userUsd420 : 0).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "yourestpayout" in asset
                      )?.yourestpayout
                    }
                  />
                </div>
                <div className="progress_content">
                  <ProgressBar
                    className="progress_bar"
                    completed={
                      getFourProgress
                        ? parseFloat(getFourProgress.toFixed(0))
                        : 0
                    }
                    bgColor="linear-gradient(90deg, rgba(254,156,1,1) 13%, rgba(186,53,5,1) 87%)"
                    isLabelVisible={true}
                  />
                  <Tooltip
                    text={
                      tooltipText.rewardpools[0].leftbox.find(
                        (asset) => "progressbar" in asset
                      )?.progressbar
                    }
                  >
                    <AiOutlineQuestionCircle />
                  </Tooltip>
                </div>
                <div className="nextpayoutday_countdown">
                  <div className="nextpayoutday">
                    <div className="nextpayoutday_title">
                      <span>Next Payout Day:</span>
                      <span className="number_next_day">
                        {" "}
                        {fourNextPayoutDay}
                      </span>
                    </div>
                    {/* <div className="nextpayoutday_value">{fourNextPayoutDay}</div> */}
                    <div className="countdown_value">{fourCountDown}</div>
                  </div>
                </div>
                <div className="reward_pool_btn_group">
                  <DistTinethTooltip>
                    <button
                      className="distribute_button"
                      id="disttitaneth"
                      disabled={
                        disableButton ||
                        !(isConnected && !isdistributeTitanxLoading)
                      }
                      onClick={distributeTitanxClicked}
                    >
                      DISTRIBUTE TITANX/ETH
                    </button>
                  </DistTinethTooltip>
                  <DistPayoutTooltip>
                    <button
                      className="distribute_button"
                      disabled={!(isConnected && !isDistributePayoutLoading)}
                      onClick={distributePayoutClicked}
                      // onClick={toggleDistPayout}
                    >
                      DISTRIBUTE PAYOUTS
                    </button>
                  </DistPayoutTooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="cols">
            <div className="reward_pool_cards">
              <div className="cards_contents">
                <h1>Titanx Treasury </h1>

                <div className="card_content_rewards">
                  <DashboardText
                    token="TITANX"
                    name="Liquid Titanx"
                    balance={parseFloat(
                      etherToFixed(liquidTitanX)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    usd={parseFloat(
                      titanx_to_usd * parseFloat(etherToFixed(liquidTitanX))
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[1].rightbox.find(
                        (asset) => "liquidtitanx" in asset
                      )?.liquidtitanx
                    }
                  />
                  <DashboardText
                    token="TITANX"
                    name="Titanx Staked"
                    balance={parseFloat(titanxStaked).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    usd={parseFloat(
                      titanx_to_usd * parseFloat(titanxStaked)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[1].rightbox.find(
                        (asset) => "titanxstaked" in asset
                      )?.titanxstaked
                    }
                  />
                  <DashboardText
                    token="ETH"
                    name="Claimable Rewards"
                    balance={parseFloat(
                      etherToFixed(claimableETH)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                    usd={parseFloat(
                      ether_to_usd * parseFloat(etherToFixed(claimableETH))
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[1].rightbox.find(
                        (asset) => "claimablerewards" in asset
                      )?.claimablerewards
                    }
                  />
                </div>
                <div className="reward_pool_btn_group">
                  <MaxStakeTooltip>
                    <button
                      className="distribute_button"
                      disabled={!(isConnected && !isMaxStakeLoading)}
                      onClick={maxStakeClicked}
                    >
                      MAX STAKE
                    </button>
                  </MaxStakeTooltip>
                  <ClaimDistTooltip claimData={claimableETH}>
                    <button
                      className="distribute_button"
                      disabled={!(isConnected && !isClaimDistributeLoading)}
                      onClick={claimDistributeClicked}
                    >
                      CLAIM & DISTRIBUTE
                    </button>
                  </ClaimDistTooltip>
                </div>
              </div>
              <div className="cards_contents">
                <h1>Wonderland Treasury </h1>
                <div className="card_content_rewards">
                  <DashboardText
                    token="QUEEN"
                    name="Red"
                    balance={parseFloat(
                      etherToFixed(bunsTreasuryQueenBalance)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    usd={(
                      parseFloat(etherToFixed(bunsTreasuryQueenBalance)) *
                      queenUsdtPrice
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[1].rightbox.find(
                        (asset) => "queenBalance" in asset
                      )?.queenBalance
                    }
                  />
                </div>
                <div className="card_content_rewards">
                  <DashboardText
                    token="CHESHIRE"
                    name="Cat"
                    balance={parseFloat(
                      etherToFixed(bunsTreasuryCheshireBalance)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    usd={(
                      parseFloat(etherToFixed(bunsTreasuryCheshireBalance)) *
                      cheshireUsdtPrice
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[1].rightbox.find(
                        (asset) => "cheshireBalance" in asset
                      )?.cheshireBalance
                    }
                  />
                </div>
                <div className="card_content_rewards">
                  <DashboardText
                    token="HATTER"
                    name="Mad"
                    balance={parseFloat(
                      etherToFixed(bunsTreasuryHatterBalance)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    usd={(
                      parseFloat(etherToFixed(bunsTreasuryHatterBalance)) *
                      hatterUsdtPrice
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[1].rightbox.find(
                        (asset) => "hatterBalance" in asset
                      )?.hatterBalance
                    }
                  />
                </div>
                <div className="card_content_rewards">
                  <DashboardText
                    token="ALICE"
                    name="Wonderland"
                    balance={parseFloat(
                      etherToFixed(bunsTreasuryAliceBalance)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    usd={(
                      parseFloat(etherToFixed(bunsTreasuryAliceBalance)) *
                      aliceUsdtPrice
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[1].rightbox.find(
                        (asset) => "aliceBalance" in asset
                      )?.aliceBalance
                    }
                  />
                </div>
                <div className="card_content_rewards">
                  <DashboardText
                    token="BUNS"
                    name="White Rabbit"
                    balance={parseFloat(
                      etherToFixed(bunsTreasuryBunsBalance)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    usd={(
                      parseFloat(etherToFixed(bunsTreasuryBunsBalance)) *
                      bunsUsdtPrice
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[1].rightbox.find(
                        (asset) => "bunsBalance" in asset
                      )?.bunsBalance
                    }
                  />
                </div>
                <div className="card_content_rewards">
                  <DashboardText
                    token="PLS"
                    name="Pulse"
                    balance={parseFloat(
                      etherToFixed(bunsTreasuryPlsBalance)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    usd={(
                      parseFloat(etherToFixed(bunsTreasuryPlsBalance)) *
                      pls_to_usd
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[1].rightbox.find(
                        (asset) => "bunsPlsBalance" in asset
                      )?.bunsPlsBalance
                    }
                  />
                </div>
              </div>
              <div className="cards_contents">
                <h1>Investment Pool </h1>

                <div className="card_content_rewards">
                  <DashboardText
                    token="ETH"
                    name="Ethereum"
                    balance={parseFloat(
                      etherToFixed(investmentBalance)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4,
                    })}
                    usd={(
                      parseFloat(etherToFixed(investmentBalance)) * ether_to_usd
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[1].rightbox.find(
                        (asset) => "balance" in asset
                      )?.balance
                    }
                  />
                </div>
                <div className="card_content_rewards">
                  <DashboardText
                    token="PLS"
                    name="Pulse"
                    balance={parseFloat(
                      etherToFixed(investmentPlsBalance)
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                    usd={(
                      parseFloat(etherToFixed(investmentPlsBalance)) *
                      pls_to_usd
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    tooltip={
                      tooltipText.rewardpools[1].rightbox.find(
                        (asset) => "plsBalance" in asset
                      )?.plsBalance
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RewardContentStyleWrapper>
  );
};
export default RewardPoolContent;
