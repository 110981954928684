import BurnPoolContent from "../components/burnpool";
const BurnPool = () => {

  return (
    <>
      <BurnPoolContent />
    </>
  );
};

export default BurnPool;
