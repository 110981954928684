import React, { useEffect, useState } from "react";
import BurnPoolStyleWrapper from "./BurnPool.style";
import RewardPoolPayout from "../../common/rewardspoolpayout/RewardPoolPayout";
import BurnPoolTime from "../../common/burnpooltime/BurnPoolTime";
import tooltipText from "../../assets/helius/tooltipText.json";
import buynburnContract from "../../contract/buyandburn.json";
import heliosContract from "../../contract/helios.json";
import jnsBuyNBurnContract from "../../contract/jnsBuynBurn.json";
import {
  useReadContracts,
  useWriteContract,
  useAccount,
  useReadContract,
  useWaitForTransactionReceipt,
  useSwitchChain,
  useChainId,
} from "wagmi";
import { useSelector } from "react-redux";
import {
  BuyandburnAddress,
  HeliosAddress,
  etherToFixed,
  JanusBuynBurnAddress,
} from "../../const/const";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BurnPoolText from "../../common/dashboardtext/BurnPoolText";
import BuyBurnTooltip from "../modal/BuyBurnTooltip";
import BuyBurnTooltipJns from "../modal/BuyBurnTooltipJns";
import { pulsechain } from "viem/chains";
import { ethers } from "ethers";

const BurnPoolContent = () => {
  const ether_to_usd = useSelector((state) => state.price.ether_to_usd); // Fetch the value of ether price from redux
  const titanx_to_usd = useSelector((state) => state.price.titanx_to_usd);
  const pls_to_usd = useSelector((state) => state.price.pls_to_usd); // Fetch the value of TitanX price from redux
  const { switchChain } = useSwitchChain();
  const chainId = useChainId();
  const { isConnected } = useAccount();
  const [switchToETHChain, setSwitchToETHChain] = useState(false);
  const [switchToPulseChain, setSwitchToPulseChain] = useState(false);
  const [wplsBalance, setWPLSBalance] = useState(0);
  const [plsSwapCap, setPlsSwapCap] = useState(0);
  const [jnsBuynBurnInterval, setJnsBuynBurnInterval] = useState(0);
  const [latestEvent, setLatestEvent] = useState(0);
  const [pulseChainLatestTimeStamp, setPulseChainLatestTimeStamp] = useState(0);
  const [jnsCheckButton, setJnsCheckButton] = useState(true);
  const [jnsCountDown, setJnsCountDown] = useState(0);

  const rpcUrl = "https://rpc.pulsechain.com";
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

  async function addCustomNetwork() {
    try {
      // Check if the Ethereum provider exists
      if (window.ethereum) {
        const networkParams = {
          chainId: "0x1",
          chainName: "MEV Blocker (Ethereum Mainnet)",
          nativeCurrency: {
            name: "Ethereum",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: ["https://rpc.mevblocker.io"],
          blockExplorerUrls: ["https://etherscan.io"],
        };

        // Request the user's wallet to add the network
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networkParams],
        });
      }
    } catch (error) {
      console.error("Could not add network:", error);
      throw error;
    }
  }

  const buyAndBurnContractObj = {
    address: BuyandburnAddress,
    abi: buynburnContract.abi,
  };
  const jnsBuyAndBurnContractObj = {
    address: JanusBuynBurnAddress,
    abi: jnsBuyNBurnContract.abi,
  };

  useEffect(() => {
    if (chainId && chainId !== 1) {
      setSwitchToETHChain(true);
    } else {
      setSwitchToPulseChain(true);
    }
  }, [chainId, switchChain]);

  useEffect(() => {
    if (chainId && chainId !== 1) {
      setSwitchToPulseChain(false);
    } else {
      setSwitchToETHChain(false);
    }
  }, [chainId, switchChain]);

  const switchChains = () => {
    if (chainId && chainId !== 1) {
      switchChain({ chainId: 1 });
    } else {
      switchChain({ chainId: pulsechain.id });
    }
  };

  const { data } = useReadContracts({
    contracts: [
      {
        ...buyAndBurnContractObj,
        functionName: "getTitanXBalance",
        chainId: 1,
      },
      {
        ...buyAndBurnContractObj,
        functionName: "getWethBalance",
        chainId: 1,
      },
      {
        ...buyAndBurnContractObj,
        functionName: "getWethBuyAndBurnCap",
        chainId: 1,
      },
      {
        ...buyAndBurnContractObj,
        functionName: "getBuyAndBurnCap",
        chainId: 1,
      },
      {
        ...buyAndBurnContractObj,
        functionName: "getBuynBurnInterval",
        chainId: 1,
      },
      {
        ...buyAndBurnContractObj,
        functionName: "getlastBuynBurnCall",
        chainId: 1,
      },
    ],
    watch: true,
  });
  const { data: jnsData } = useReadContracts({
    contracts: [
      {
        ...jnsBuyAndBurnContractObj,
        functionName: "getWPLSBalance",
        chainId: pulsechain.id,
      },
      {
        ...jnsBuyAndBurnContractObj,
        functionName: "getBuyAndBurnCap",
        chainId: pulsechain.id,
      },
      {
        ...jnsBuyAndBurnContractObj,
        functionName: "getBuynBurnInterval",
        chainId: pulsechain.id,
      },
    ],
    watch: true,
  });

  useEffect(() => {
    if (jnsData && jnsData[0].status === "success") {
      setWPLSBalance(etherToFixed(jnsData ? jnsData[0].result : 0));
      setPlsSwapCap(etherToFixed(jnsData ? jnsData[1].result : 0));
      setJnsBuynBurnInterval(jnsData ? jnsData[2].result : 0);
    }
  }, [jnsData]);
  useEffect(() => {
    const contract = new ethers.Contract(
      JanusBuynBurnAddress,
      jnsBuyNBurnContract.abi,
      provider
    );

    const getLatestBoughtAndBurnedEvent = async () => {
      const filter = contract.filters.BoughtAndBurned();
      const events = await contract.queryFilter(filter, 20708644, "latest");
      if (events.length > 0) {
        const latest = events[events.length - 1];
        const block = await provider.getBlock(latest.blockNumber);
        const latestBlock = await provider.getBlock("latest");
        setLatestEvent(block.timestamp);
        setPulseChainLatestTimeStamp(latestBlock.timestamp);
      } else {
        setLatestEvent(0);
      }
    };

    getLatestBoughtAndBurnedEvent();
  }, []);

  useEffect(() => {
    const timer =
      parseInt(jnsBuynBurnInterval.toString()) +
      parseInt(latestEvent.toString()) -
      parseInt(pulseChainLatestTimeStamp.toString());
    if (timer < 0) {
      setJnsCheckButton(false);
      setJnsCountDown(0);
    } else {
      setJnsCheckButton(true);
      setJnsCountDown(timer);
    }
  }, [jnsBuynBurnInterval, latestEvent, pulseChainLatestTimeStamp]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (jnsCountDown > 0) {
        setJnsCountDown(jnsCountDown - 1);
      } else {
        clearInterval(timer);
        setJnsCheckButton(false);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [jnsCountDown, jnsCheckButton]);

  const {
    data: jnsHash,
    writeContract: jnsBuynBurn,
    error: jnsWriteError,
    isLoading: isjnsbuynburnLoading,
  } = useWriteContract();

  const { isLoading: isJnsConfirming, isSuccess: isJnsConfirmed } =
    useWaitForTransactionReceipt({
      hash: jnsHash,
    });

  useEffect(() => {
    if (jnsWriteError) {
      toast.error(jnsWriteError.message.slice(0, 50) + "...", {
        autoClose: 5000,
      });
    }
  }, [jnsWriteError]);

  useEffect(() => {
    if (isJnsConfirmed) {
      toast.success("Burned successfully", {
        autoClose: 5000,
      });
    }
  }, [isJnsConfirmed]);

  const {
    data: hash,
    writeContract: buynburn,
    error: writeError,
    isLoading: isbuynburnLoading,
  } = useWriteContract();

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  useEffect(() => {
    if (writeError) {
      toast.error(writeError.message.slice(0, 50) + "...", {
        autoClose: 5000,
      });
    }
  }, [writeError]);

  useEffect(() => {
    if (isConfirmed) {
      toast.success("Burned successfully", {
        autoClose: 5000,
      });
    }
  }, [isConfirmed]);

  const { data: currentBlockTimeStamp } = useReadContract({
    address: HeliosAddress,
    abi: heliosContract.abi,
    functionName: "getCurrentBlockTimeStamp",
    watch: true,
    chainId: 1,
  });
  // --------- ------------
  const [titanBalance, setTitanBalance] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [getUsdBalance, setGetUsdBalance] = useState();
  const [ethSwapCap, setETHSwapCap] = useState(0);
  const [swapCap, setSwapCap] = useState(0);
  const [buynBurnInterval, setBuynBurnInterval] = useState(0);
  const [getUsdCapSwap, setGetUsdCapSwap] = useState();
  const [checkButton, setCheckButton] = useState(true);
  const [countDown, setCountdown] = useState(0);
  const convertToHourFormat = (timer) => {
    const remainingSeconds = timer % 86400; // Calculate remaining seconds after subtracting days
    const date = new Date(remainingSeconds * 1000); // Convert remaining seconds to date
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const seconds = date.getUTCSeconds().toString().padStart(2, "0");
    return `${hours}H:${minutes}M:${seconds}S`;
  };
  useEffect(() => {
    if (data && data[0].status === "success") {
      setTitanBalance(etherToFixed(data[0] ? data[0].result.toString() : 0));
      setEthBalance(etherToFixed(data[1] ? data[1].result.toString() : 0));
      setETHSwapCap(etherToFixed(data[2] ? data[2].result.toString() : 0));
      setSwapCap(data[3] ? etherToFixed(data[3].result.toString()) : "0.00");
      setBuynBurnInterval(
        (data[4] ? parseFloat(data[4].result) / 3600 : 0).toFixed(2)
      );
      const buyburnInterval = data[4] ? parseFloat(data[4].result) : 0;
      const lastBurnCall = data[5] ? parseFloat(data[5].result) : 0;
      const currentBlockTime = currentBlockTimeStamp
        ? currentBlockTimeStamp.toString()
        : 0;

      console.log({ buyburnInterval });
      console.log({ lastBurnCall });
      console.log({ currentBlockTime });
      const timer =
        parseInt(buyburnInterval.toString()) +
        parseInt(lastBurnCall.toString()) -
        parseInt(currentBlockTime.toString());
      if (timer < 0) {
        setCheckButton(false);
        setCountdown(0);
      } else {
        setCheckButton(true);
        setCountdown(timer);
      }
    }
    setGetUsdBalance(
      (
        parseFloat(
          parseFloat(titanBalance ? titanBalance : 0) * titanx_to_usd
        ) + parseFloat(parseFloat(ethBalance ? ethBalance : 0) * ether_to_usd)
      ).toFixed(2)
    );
    const usdcap = (
      parseFloat(swapCap ? swapCap : 0) * titanx_to_usd +
      parseFloat((ethSwapCap ? ethSwapCap : 0) * ether_to_usd)
    ).toFixed(2);
    setGetUsdCapSwap(usdcap);
  }, [
    data,
    ethBalance,
    swapCap,
    ethSwapCap,
    titanBalance,
    ether_to_usd,
    titanx_to_usd,
    buynBurnInterval,
    currentBlockTimeStamp,
  ]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (countDown > 0) {
        setCountdown(countDown - 1);
      } else {
        clearInterval(timer);
        setCheckButton(false);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [countDown, checkButton]);

  const buyandburnClicked = async () => {
    if (checkButton === true) {
      toast.error(
        "Minimum wait time has not been exceeded.  Please wait and try again.",
        { autoClose: 5000 }
      );
    } else {
      try {
        await addCustomNetwork();
        buynburn({
          address: BuyandburnAddress,
          abi: buynburnContract.abi,
          functionName: "buynBurn",
          args: [],
        });
      } catch (error) {
        if (error.code === 4001) {
          toast.error(
            "Please accept switching RPC providers to protect from MEV attacks",
            { autoClose: 6000 }
          );
        }
        console.error("An unexpected error occurred:", error);
      }
    }
  };

  const buyandburnJnsClicked = async () => {
    if (jnsCheckButton === true) {
      toast.error(
        "Minimum wait time has not been exceeded.  Please wait and try again.",
        { autoClose: 5000 }
      );
    } else {
      try {
        jnsBuynBurn({
          address: JanusBuynBurnAddress,
          abi: jnsBuyNBurnContract.abi,
          functionName: "buynBurn",
          args: [],
        });
      } catch (error) {
        if (error.code === 4001) {
          toast.error(
            "Please accept switching RPC providers to protect from MEV attacks",
            { autoClose: 6000 }
          );
        }
        console.error("An unexpected error occurred:", error);
      }
    }
  };

  return (
    <BurnPoolStyleWrapper>
      <div className="dashboard_container">
        <div className="ether_subtitle">
          <h2>Burn, Baby, Burn 🔥</h2>
        </div>
        <div className="row">
          <div className="col">
            <div className="burnpool_cards">
              <div className="burnpool_title">
                <p className="burnpool_card_title">JNS BURN POOL</p>
              </div>
              <div className="burnpool_cards_content">
                <BurnPoolText
                  token="PLS"
                  name="BALANCE"
                  balance={parseFloat(wplsBalance).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={parseFloat(wplsBalance * pls_to_usd).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                  tooltip={
                    tooltipText.burnpools[0].leftbox.find(
                      (asset) => "janusburnpoolbalance" in asset
                    )?.janusburnpoolbalance
                  }
                />
                <div style={{ visibility: "hidden", height: "15px" }}>asdf</div>
                <BurnPoolText
                  token="PLS"
                  name="CAP PER SWAP"
                  balance={parseFloat(plsSwapCap).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  usd={parseFloat(plsSwapCap * pls_to_usd).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                  tooltip={
                    tooltipText.burnpools[0].leftbox.find(
                      (asset) => "capperswap" in asset
                    )?.capperswap
                  }
                />
                <div style={{ visibility: "hidden", height: "15px" }}>asdf</div>
                <BurnPoolText
                  name="CALLABLE EVERY"
                  balance={parseFloat(
                    parseFloat(jnsBuynBurnInterval.toString()) / 60 >= 60
                      ? parseFloat(jnsBuynBurnInterval.toString()) / 60 / 60
                      : parseFloat(jnsBuynBurnInterval.toString()) / 60
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  token={
                    parseFloat(
                      parseFloat(jnsBuynBurnInterval.toString()) / 60
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }) >= 60
                      ? parseFloat(jnsBuynBurnInterval.toString()) / 60 / 60 > 1
                        ? "HOURS"
                        : "HOUR"
                      : parseFloat(jnsBuynBurnInterval.toString()) / 60 > 1
                      ? "MINUTES"
                      : "MINUTE"
                  }
                  usd="hour"
                  tooltip={
                    tooltipText.burnpools[0].leftbox.find(
                      (asset) => "callableevery" in asset
                    )?.callableevery
                  }
                />
              </div>
              <div className="trigger_btn">
                <BuyBurnTooltipJns>
                  <button
                    disabled={!(isConnected && !isjnsbuynburnLoading)}
                    onClick={
                      switchToPulseChain ? switchChains : buyandburnJnsClicked
                    }
                  >
                    {switchToPulseChain
                      ? "Switch To Pulse Chain"
                      : "BUY & BURN"}
                  </button>
                </BuyBurnTooltipJns>
              </div>
              <div className="timer_text">
                {convertToHourFormat(jnsCountDown)}{" "}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="burnpool_cards">
              <p className="burnpool_card_title">HLX BURN POOL</p>
              <div className="burnpool_cards_content">
                <RewardPoolPayout
                  name="BALANCE"
                  titanx={parseFloat(titanBalance).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  ether={parseFloat(ethBalance).toLocaleString("en-US", {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}
                  usd={parseFloat(getUsdBalance).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.burnpools[1].rightbox.find(
                      (asset) => "hlxburnpoolbalance" in asset
                    )?.hlxburnpoolbalance
                  }
                />
                <RewardPoolPayout
                  name="CAP PER SWAP"
                  titanx={parseFloat(swapCap).toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  ether={parseFloat(ethSwapCap).toLocaleString("en-US", {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  })}
                  usd={parseFloat(getUsdCapSwap).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                  tooltip={
                    tooltipText.burnpools[1].rightbox.find(
                      (asset) => "capperswap" in asset
                    )?.capperswap
                  }
                />
                <BurnPoolTime
                  name="CALLABLE EVERY"
                  hour={buynBurnInterval}
                  tooltip={
                    tooltipText.burnpools[1].rightbox.find(
                      (asset) => "callableevery" in asset
                    )?.callableevery
                  }
                />
              </div>
              <div className="trigger_btn">
                <BuyBurnTooltip>
                  <button
                    disabled={
                      !(isConnected && !isbuynburnLoading && isConfirming)
                    }
                    onClick={
                      switchToETHChain ? switchChains : buyandburnClicked
                    }
                  >
                    {switchToETHChain ? "Switch to Ethreum" : "BUY & BURN"}
                  </button>
                </BuyBurnTooltip>
              </div>
              <div className="timer_text">
                {convertToHourFormat(countDown)}{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="modal_text">
          <p>
            Use{" "}
            <a
              href="https://mevblocker.io/#rpc"
              style={{ color: "white", textDecoration: "underline" }}
            >
              MEVBlocker.io
            </a>{" "}
            RPC network when calling the Buy & Burn. It makes the buy & burn a
            lot more lucrative and effective. It's also a security best
            practice. Learn more{" "}
            <a
              href="https://www.youtube.com/watch?v=Zou5TA5fdLI&ab_channel=CyberScrilla"
              style={{ color: "white", textDecoration: "underline" }}
            >
              here
            </a>{" "}
            — it's 1-click, takes 2 seconds & only needs to be done once.
          </p>
        </div>
      </div>
    </BurnPoolStyleWrapper>
  );
};;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
export default BurnPoolContent;
