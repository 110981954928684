import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from "./utils/ContextProvider";
import App from "./app/App";
import { Provider } from "react-redux";
import store from "../src/store/store";
import "@rainbow-me/rainbowkit/styles.css";

import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultConfig,
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { mainnet, pulsechain } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const config = getDefaultConfig({
  appName: "Helios",
  projectId: "1afdbee58dd98d25ccb6c3c6e13dc912",
  chains: [mainnet, pulsechain],
  rpcUrls: {
    default: {
      http: ["https://lb.nodies.app/v1/0a6ecd2caf9f4cb8a10685841dbcb9fe"],
    },
  },
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider
        modalSize="wide" //wide,compact
        chains={{ mainnet }}
        theme={darkTheme({
          borderRadius: "large",
          fontStack: "montserrat",
          overlayBlur: "small",
          connectButtonBackground: "#BA3505",
          connectButtonText: "white",
          accentColor: "#BA3505",
          accentColorForeground: "white",
          closeButtonBackground: "#BA3505",
        })}
      >
        <ContextProvider>
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </ContextProvider>
      </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>
);
