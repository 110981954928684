import JanusContent from "../components/janus";

const Janus = () => {
  return (
    <>
      <JanusContent />
    </>
  );
};

export default Janus;
