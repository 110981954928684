import { createSlice } from "@reduxjs/toolkit";
import { getEthertoUsd, getHlxtoUsd, getTitanxtoUsd } from "./actions";

const PREFIX = "Price";

const initialState = {
  ether_to_usd: "2000",
  titanx_to_usd: "0.0000001",
  hlx_to_usd: "0.000001",
  pls_to_usd: "0.00000001",
  jns_to_usd: "0.00000000001",
};

export const price = createSlice({
  name: PREFIX,
  initialState,
  reducers: {
    // Define reducers to set custom values
    setEtherToUsd: (state, action) => {
      state.ether_to_usd = action.payload;
    },
    setTitanxToUsd: (state, action) => {
      state.titanx_to_usd = action.payload;
    },
    setHlxToUsd: (state, action) => {
      state.hlx_to_usd = action.payload;
    },
    setPlsToUsd: (state, action) => {
      state.pls_to_usd = action.payload;
    },
    setJnsToUsd: (state, action) => {
      state.jns_to_usd = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHlxtoUsd.fulfilled, (state, action) => {
      state.hlx_to_usd = action.payload;
    });
    builder.addCase(getTitanxtoUsd.fulfilled, (state, action) => {
      state.titanx_to_usd = action.payload;
    });
    builder.addCase(getEthertoUsd.fulfilled, (state, action) => {
      state.ether_to_usd = action.payload;
    });
  },
});

export const {
  setEtherToUsd,
  setTitanxToUsd,
  setHlxToUsd,
  setJnsToUsd,
  setPlsToUsd,
} = price.actions;

export default price.reducer;
