import "./ClaimDistToolTip.css";
import jnsBuyNBurnContract from "../../contract/jnsBuynBurn.json";
import { useReadContracts } from "wagmi";
import { useSelector } from "react-redux";
import { pulsechain } from "viem/chains";
import { JanusBuynBurnAddress, etherToFixed } from "../../const/const";
import { useEffect, useState } from "react";

const BuyBurnTooltipJns = (props) => {
  const { children } = props;
  const pls_to_usd = useSelector((state) => state.price.pls_to_usd); // Fetch the value of TitanX price from redux

  const { data: buyburn } = useReadContracts({
    contracts: [
      {
        address: JanusBuynBurnAddress,
        abi: jnsBuyNBurnContract.abi,
        functionName: "getBuyAndBurnCap",
        chainId: pulsechain.id,
      },
      {
        address: JanusBuynBurnAddress,
        abi: jnsBuyNBurnContract.abi,
        functionName: "getBuynBurnIncentiveFee",
        chainId: pulsechain.id,
      },
    ],
    watch: true,
  });

  const [userReward, setUserReward] = useState(0);
  const [userRewardUsd, setUserRewardUsd] = useState(0);

  useEffect(() => {
    if (buyburn && buyburn[0].status === "success") {
      console.log("buyburn", buyburn[0].result);
      setUserReward(
        (
          etherToFixed(buyburn ? buyburn[0].result : 0) *
          (buyburn ? parseFloat(buyburn[1].result.toString()) / 1000000 : 0)
        ).toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      );
      setUserRewardUsd(
        parseFloat(
          etherToFixed(buyburn ? buyburn[0].result : 0) *
            (buyburn ? parseFloat(buyburn[1].result.toString()) / 1000000 : 0) *
            pls_to_usd
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
  }, [buyburn, pls_to_usd]);

  return (
    <div className="buyburntooltip">
      {children}
      <div className="buyburntooltip_content">
        <main className="modal_content">
          <div className="content_container">
            <div className="counter_box_buyburn">
              <div className="token_counter">
                <div className="token_counter_name">
                  <span>User Reward</span>
                </div>
                <div className="token_value">{userReward} PLS</div>
              </div>
              <div className="dollar_counter">≈${userRewardUsd}</div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default BuyBurnTooltipJns;
