import MineContent from "../components/section/mine";

const Mine = () => {

  return (
    <>
      <MineContent />
    </>
  );
};

export default Mine;
