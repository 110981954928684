import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getEthertoUsd = createAsyncThunk("get/getEtherToUsd", async () => {
  try {
    const response = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`
    );
    localStorage.setItem("etherUsdPrice", response.data.ethereum.usd);
    return response.data.ethereum.usd;
  } catch (error) {
    return localStorage.getItem("etherUsdPrice");
  }
});

export const getTitanxtoUsd = createAsyncThunk(
  "get/getTitanXToUsd",
  async () => {
    try {
      const response = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=TITANX&vs_currencies=usd`
      );
      localStorage.setItem("titanxUsdPrice", response.data.titanx.usd);
      return response.data.titanx.usd;
    } catch (error) {
      return localStorage.getItem("titanxUsdPrice");
    }
  }
);

export const getHlxtoUsd = createAsyncThunk("get/getHLXToUsd", async () => {
  try {
    const response = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=helios&vs_currencies=usd`
    );
    localStorage.setItem("heliosUsdPrice", response.data.helios.usd);
    return response.data.helios.usd;
  } catch (error) {
    return localStorage.getItem("heliosUsdPrice");
  }
});
