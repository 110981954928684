import React, { useEffect, useState } from "react";
import JanusStyleWrapper from "./JanusContent.style";
import Logo from "../../assets/helius/logo_coin.png";
import Bridge from "../../assets/helius/bridge.svg";
import HLXLogo from "../../assets/helius/HLX.png";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import {
  JanusContractAddress,
  HeliosPulseChainAddress,
  JanusLpManagerAddress,
  etherToFixed,
  etherToFixed8Decimals,
  formatNumber,
  pulseXFactoryAddress,
} from "../../const/const";
import { ConnectButton, connectorsForWallets } from "@rainbow-me/rainbowkit";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useAccount,
  useReadContracts,
  useSwitchChain,
  useChainId,
  useWriteContract,
  useWaitForTransactionReceipt,
  useBalance,
  useBlock,
} from "wagmi";
import { pulsechain } from "wagmi/chains";
import janusContract from "../../contract/janus.json";
import heliosContract from "../../contract/helios.json";
import janusLpManagerContract from "../../contract/janusManager.json";
import pulseXFactoryContract from "../../contract/pulseXFactory.json";
import wplsContract from "../../contract/wpls.json";
import poolContract from "../../contract/wplsPool.json";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import Tooltip from "../../common/tooltip";
import Select from "react-select";

const JanusContent = () => {
  const [ToggleState, setToggleState] = useState(1);
  const hlx_to_usd = useSelector((state) => state.price.hlx_to_usd); // Fetch the value of HLX price from redux
  const jns_to_usd = useSelector((state) => state.price.jns_to_usd); // Fetch the value of Jns price from redux
  const pls_to_usd = useSelector((state) => state.price.pls_to_usd); // Fetch the value of PLS price from redux
  const { switchChain } = useSwitchChain();
  const chainId = useChainId();
  const { address, isConnected } = useAccount();
  const userPlsBalance = useBalance({
    address,
    chainId: pulsechain.id,
  });

  const [selectedTokenLp, setSelectedTokenLp] = useState("");
  const [selectedTokenLpPosition, setSelectedTokenLpPosition] = useState("");

  const rpcUrl = "https://rpc.pulsechain.com";
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const blockResult = useBlock({ chainId: pulsechain.id });

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  const janusContractObj = {
    address: JanusContractAddress,
    abi: janusContract.abi,
  };
  const heliosContractObj = {
    address: HeliosPulseChainAddress,
    abi: heliosContract.abi,
  };
  const janusLpContractObj = {
    address: JanusLpManagerAddress,
    abi: janusLpManagerContract.abi,
  };

  const pulseXFactoryObj = {
    address: pulseXFactoryAddress,
    abi: pulseXFactoryContract.abi,
  };

  const selectedTokenPoolObj = {
    address: selectedTokenLp,
    abi: poolContract.abi,
  };

  const selectedTokenPositionPoolObj = {
    address: selectedTokenLpPosition,
    abi: poolContract.abi,
  };

  const [heliosAmount, setHeliosAmount] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [currentPlsRate, setCurrentPlsRate] = useState(0);
  const [plsAmount, setPlsAmount] = useState(0);
  const [switchChains, setSwitchChains] = useState(false);
  const [heliosAllowance, setHeliosAllowance] = useState(0);
  const [selectedTokenAllowance, setSelectedTokenAllowance] = useState(0);
  const [janusBalance, setJanusBalance] = useState(0);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isLpConfirming, setIsLpConfirming] = useState(false);
  const [isClaimLpConfirming, setIsClaimLpConfirming] = useState(false);
  const [plsBalance, setPLSBalance] = useState(0);
  const [whiteListedTokens, setWhiteListedTokens] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [selectedToken, setSelectedToken] = useState("");
  const [selectedTokenPosition, setSelectedTokenPosition] = useState("");
  const [selectedTokenSymbol, setSelectedTokenSymbol] = useState("");
  const [selectedTokenSymbolPosition, setSelectedTokenSymbolPosition] =
    useState("");
  const [selectedTokenAmount, setSelectedTokenAmount] = useState(0);
  const [jnsAmount, setJNSAmount] = useState(0);
  const [minimumJnsAmount, setMinimumJNSAmount] = useState(0);
  const [selectedTokenBalance, setSelectedTokenBalance] = useState(0);
  const [selectedTokenStakeIds, setSelectedTokenStakeIds] = useState([]);
  const [selectedTokenStakes, setSelectedTokenStakes] = useState([]);
  const [selectedStakeId, setSelectedStakeId] = useState("");
  const [selectedStake, setSelectedStake] = useState({});
  const [currentTimeStamp, setCurrentTimeStamp] = useState(0);
  const [daysPassed, setDaysPassed] = useState(0);
  const [returnPercentage, setReturnPercentage] = useState(0);
  const [selectedTokenPrice, setSelectedTokenPrice] = useState(0);
  const [selectedTokenPositionLpSupply, setSelectedTokenPositionLpSupply] =
    useState(0);
  const [selectedTokenPositionLpToken0, setSelectedTokenPositionLpToken0] =
    useState(0);
  const [selectedTokenPositionLpReserves, setSelectedTokenPositionLpReserves] =
    useState(0);
  const [selectedTokenPositionPercentage, setSelectedTokenPositionPercentage] =
    useState(0);
  const [selectedTokenPositionData, setSelectedTokenPositionData] = useState({
    tokenAmount: 0,
    jnsAmount: 0,
  });

  const calculateDaysPassed = (startTime) => {
    const timeDifference = currentTimeStamp - startTime;
    const days = Math.floor(timeDifference / (60 * 60 * 24));
    return days;
  };

  const onChangeInputHandler = (event, type) => {
    const newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      switch (type) {
        case "heliosAmount":
          setHeliosAmount(newValue);
          break;
        default:
          break;
      }
    }
  };
  const { data } = useReadContracts({
    contracts: [
      {
        ...heliosContractObj,
        functionName: "balanceOf",
        args: [address],
        chainId: pulsechain.id,
      },
      {
        ...heliosContractObj,
        functionName: "allowance",
        args: [address, JanusContractAddress],
        chainId: pulsechain.id,
      },
    ],
    watch: true,
  });

  const { data: lpAddress } = useReadContracts({
    contracts: [
      {
        ...pulseXFactoryObj,
        functionName: "getPair",
        args: [JanusContractAddress, selectedToken],
        chainId: pulsechain.id,
      },
      {
        ...pulseXFactoryObj,
        functionName: "getPair",
        args: [JanusContractAddress, selectedTokenPosition],
        chainId: pulsechain.id,
      },
    ],
    watch: true,
  });

  const { data: poolData } = useReadContracts({
    contracts: [
      {
        ...selectedTokenPoolObj,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        ...selectedTokenPoolObj,
        functionName: "getReserves",
      },
    ],
    watch: true,
  });

  const { data: poolDataPosition } = useReadContracts({
    contracts: [
      {
        ...selectedTokenPositionPoolObj,
        functionName: "totalSupply",
      },
      {
        ...selectedTokenPositionPoolObj,
        functionName: "token0",
        chainId: pulsechain.id,
      },
      {
        ...selectedTokenPositionPoolObj,
        functionName: "getReserves",
      },
    ],
    watch: true,
  });

  const { data: janusLpData } = useReadContracts({
    contracts: [
      {
        ...janusLpContractObj,
        functionName: "getWhitelistedTokens",
        chainId: pulsechain.id,
      },
      {
        ...janusLpContractObj,
        functionName: "getJNSContribution",
        args: [
          selectedTokenSymbol === "WBTC"
            ? ethers.utils.parseUnits(
                selectedTokenAmount ? selectedTokenAmount.toString() : "0",
                8
              )
            : ethers.utils.parseEther(
                selectedTokenAmount ? selectedTokenAmount.toString() : "0"
              ),
          selectedToken,
        ],
        chainId: pulsechain.id,
      },
      {
        ...janusLpContractObj,
        functionName: "getStakeIds",
        args: [address, selectedTokenPosition],
        chainId: pulsechain.id,
      },
      {
        ...janusLpContractObj,
        functionName: "getUserShare",
        args: [daysPassed],
        chainId: pulsechain.id,
      },
      {
        ...janusLpContractObj,
        functionName: "minimumDeposit",
        chainId: pulsechain.id,
      },
    ],
    watch: true,
  });

  const { data: lpAllowance } = useReadContracts({
    contracts: [
      {
        address: selectedToken,
        abi: janusContractObj.abi,
        functionName: "allowance",
        args: [address, JanusLpManagerAddress],
        chainId: pulsechain.id,
      },
    ],
    watch: true,
  });

  useEffect(() => {
    if (lpAllowance && lpAllowance[0].status === "success") {
      setSelectedTokenAllowance(
        lpAllowance[0]
          ? lpAllowance[0]?.status === "success"
            ? selectedTokenSymbol === "WBTC"
              ? etherToFixed8Decimals(lpAllowance[0]?.result)
              : etherToFixed(lpAllowance[0]?.result)
            : 0
          : 0
      );
    }
  }, [lpAllowance, selectedTokenSymbol]);

  useEffect(() => {
    if (lpAddress && lpAddress[0].status === "success") {
      setSelectedTokenLp(
        lpAddress[0]
          ? lpAddress[0]?.status === "success"
            ? lpAddress[0]?.result
            : ""
          : ""
      );
    }
    if (lpAddress && lpAddress[1].status === "success") {
      setSelectedTokenLpPosition(
        lpAddress[1]
          ? lpAddress[1]?.status === "success"
            ? lpAddress[1]?.result
            : ""
          : ""
      );
    }
  }, [lpAddress]);

  useEffect(() => {
    if (blockResult?.data?.timestamp) {
      setCurrentTimeStamp(blockResult.data.timestamp.toString());
    }
  }, [blockResult]);

  useEffect(() => {
    if (selectedStakeId && selectedTokenStakes) {
      const stake = selectedTokenStakes.find(
        (stakeArray) => stakeArray[0].toString() === selectedStakeId
      );
      console.log({ stake });
      if (stake) {
        const stakeObject = {
          stakeId: stake[0],
          amount: stake[1],
          startTime: stake[2],
          pair: stake[3],
          tokenAmount: stake[4],
          jnsAmount: stake[5],
        };
        setSelectedStake(stakeObject);

        if (currentTimeStamp) {
          const days = calculateDaysPassed(stakeObject.startTime);
          setDaysPassed(days);
        }
      }
    }
  }, [selectedStakeId, selectedTokenStakes, currentTimeStamp]);

  useEffect(() => {
    const fetchTokenSymbols = async () => {
      try {
        const tokenDataPromises = whiteListedTokens.map(
          async (tokenAddress) => {
            const contract = new ethers.Contract(
              tokenAddress,
              heliosContract.abi,
              provider
            );
            const symbol = await contract.symbol();
            return { tokenAddress, symbol };
          }
        );

        const tokensData = await Promise.all(tokenDataPromises);
        setTokens(tokensData);
      } catch (error) {
        console.error("Error fetching token symbols:", error);
      }
    };

    if (whiteListedTokens.length > 0) {
      fetchTokenSymbols();
    }
  }, [whiteListedTokens]);

  useEffect(() => {
    const fetchLpReserves = async () => {
      try {
        const contract = new ethers.Contract(
          selectedTokenLp,
          poolContract.abi,
          provider
        );
        const reserves = await contract.getReserves();
        const token0 = poolData[0].result;
        if (token0 === JanusContractAddress) {
          const selectedTokenPriceInJns =
            etherToFixed(parseFloat(reserves[0].toString())) /
            (selectedTokenSymbol === "WBTC"
              ? etherToFixed8Decimals(parseFloat(reserves[1].toString()))
              : etherToFixed(parseFloat(reserves[1].toString())));
          setSelectedTokenPrice(
            (
              parseFloat(selectedTokenPriceInJns.toString()) *
              parseFloat(jns_to_usd)
            ).toFixed(18)
          );

          console.log(
            "PRice:",
            (
              parseFloat(selectedTokenPriceInJns.toString()) *
              parseFloat(jns_to_usd)
            ).toFixed(18)
          );
        } else {
          const selectedTokenPriceInJns =
            etherToFixed(parseFloat(reserves[1].toString())) /
            (selectedTokenSymbol === "WBTC"
              ? etherToFixed8Decimals(parseFloat(reserves[0].toString()))
              : etherToFixed(parseFloat(reserves[0].toString())));

          setSelectedTokenPrice(
            (
              parseFloat(selectedTokenPriceInJns.toString()) *
              parseFloat(jns_to_usd)
            ).toFixed(18)
          );

          console.log(
            "PRice:",
            (
              parseFloat(selectedTokenPriceInJns.toString()) *
              parseFloat(jns_to_usd)
            ).toFixed(18)
          );
        }
      } catch (error) {
        console.error("Error fetching token symbols:", error);
      }
    };

    if (
      selectedTokenLp !== "" &&
      poolData &&
      poolData[0].status === "success"
    ) {
      fetchLpReserves();
    }
  }, [selectedTokenLp, poolData, jns_to_usd, selectedTokenSymbol]);

  useEffect(() => {
    if (poolDataPosition && poolDataPosition[0].status === "success") {
      setSelectedTokenPositionLpSupply(
        poolDataPosition[0]
          ? poolDataPosition[0]?.status === "success"
            ? poolDataPosition[0]?.result
            : 0
          : 0
      );
    }
    if (poolDataPosition && poolDataPosition[1].status === "success") {
      setSelectedTokenPositionLpToken0(
        poolDataPosition[1]
          ? poolDataPosition[1]?.status === "success"
            ? poolDataPosition[1]?.result
            : ""
          : ""
      );
    }
    if (poolDataPosition && poolDataPosition[2].status === "success") {
      setSelectedTokenPositionLpReserves(
        poolDataPosition[2]
          ? poolDataPosition[2]?.status === "success"
            ? poolDataPosition[2]?.result
            : ""
          : ""
      );
    }
  }, [selectedTokenLpPosition, poolDataPosition]);

  useEffect(() => {
    if (selectedTokenPositionLpSupply > 0) {
      setSelectedTokenPositionPercentage(
        (parseFloat(selectedStake.amount) /
          parseFloat(selectedTokenPositionLpSupply)) *
          100
      );
      console.log({ selectedStake });
    }
  }, [selectedTokenPositionLpSupply, selectedStake]);

  useEffect(() => {
    if (selectedTokenPositionPercentage && selectedTokenPositionLpReserves) {
      const tokenAmount =
        selectedTokenPosition === selectedTokenPositionLpToken0
          ? (parseFloat(selectedTokenPositionPercentage) *
              parseFloat(selectedTokenPositionLpReserves[0])) /
            parseFloat(100)
          : (parseFloat(selectedTokenPositionPercentage) *
              parseFloat(selectedTokenPositionLpReserves[1])) /
            parseFloat(100);

      const jnsAmount =
        selectedTokenPosition === selectedTokenPositionLpToken0
          ? (parseFloat(selectedTokenPositionPercentage) *
              parseFloat(selectedTokenPositionLpReserves[1])) /
            parseFloat(100)
          : (parseFloat(selectedTokenPositionPercentage) *
              parseFloat(selectedTokenPositionLpReserves[0])) /
            parseFloat(100);

      console.log({ tokenAmount });
      console.log({ jnsAmount });

      setSelectedTokenPositionData({
        tokenAmount: tokenAmount,
        jnsAmount: jnsAmount,
      });
    }
  }, [
    selectedTokenPositionPercentage,
    selectedTokenPositionLpReserves,
    selectedTokenPosition,
    selectedTokenPositionLpToken0,
  ]);

  const { data: janusReads } = useReadContracts({
    contracts: [
      {
        ...janusContractObj,
        functionName: "plsRate",
        chainId: pulsechain.id,
      },
      {
        ...janusContractObj,
        functionName: "balanceOf",
        args: [address],
        chainId: pulsechain.id,
      },
    ],
    watch: true,
  });

  useEffect(() => {
    if (janusLpData && janusLpData[0].status === "success") {
      setWhiteListedTokens(
        janusLpData[0]
          ? janusLpData[0]?.status === "success"
            ? janusLpData[0]?.result
            : []
          : []
      );
    }
    if (janusLpData && janusLpData[1].status === "success") {
      setJNSAmount(
        janusLpData[1]
          ? janusLpData[1]?.status === "success"
            ? etherToFixed(janusLpData[1]?.result)
            : 0
          : 0
      );
    }
    if (janusLpData && janusLpData[2].status === "success") {
      setSelectedTokenStakeIds(
        janusLpData[2]
          ? janusLpData[2]?.status === "success"
            ? janusLpData[2]?.result
            : []
          : []
      );
    }
    if (janusLpData && janusLpData[3].status === "success") {
      setReturnPercentage(
        janusLpData[3]
          ? janusLpData[3]?.status === "success"
            ? Number(janusLpData[3]?.result) / 100
            : 0
          : 0
      );
    }
    if (janusLpData && janusLpData[1].status === "success") {
      setMinimumJNSAmount(
        janusLpData[4]
          ? janusLpData[4]?.status === "success"
            ? etherToFixed(janusLpData[4]?.result)
            : 0
          : 0
      );
    }
  }, [janusLpData]);

  useEffect(() => {
    const fetchStakeDetails = async () => {
      if (selectedTokenStakeIds.length > 0) {
        const stakeDetailsPromises = selectedTokenStakeIds.map(
          async (stakeId) => {
            const contract = new ethers.Contract(
              JanusLpManagerAddress,
              janusLpManagerContract.abi,
              provider
            );
            return await contract.stakes(
              address,
              selectedTokenPosition,
              stakeId
            );
          }
        );

        try {
          const stakes = await Promise.all(stakeDetailsPromises);
          if (
            stakes.length > 0 &&
            stakes[0].stakeId !==
              "0x0000000000000000000000000000000000000000000000000000000000000000"
          ) {
            setSelectedTokenStakes(stakes);
          } else {
            setSelectedTokenStakes([]);
          }
        } catch (error) {
          console.error("Error fetching stake details:", error);
        }
      }
    };

    fetchStakeDetails();
  }, [selectedTokenStakeIds, address, selectedTokenPosition]);

  useEffect(() => {
    if (data) {
      setUserBalance(
        data[0]
          ? data[0]?.status === "success"
            ? data[0]?.result?.toString()
            : 0
          : 0
      );
      setHeliosAllowance(
        data[1]
          ? data[1]?.status === "success"
            ? data[1]?.result?.toString()
            : 0
          : 0
      );
    }
  }, [data]);
  useEffect(() => {
    if (userPlsBalance && userPlsBalance.isSuccess) {
      setPLSBalance(userPlsBalance.data.value);
    }
  }, [userPlsBalance]);

  useEffect(() => {
    if (janusReads) {
      setCurrentPlsRate(
        janusReads[0]
          ? janusReads[0]?.status === "success"
            ? janusReads[0]?.result?.toString()
            : 0
          : 0
      );
      setJanusBalance(
        janusReads[1]
          ? janusReads[1]?.status === "success"
            ? janusReads[1]?.result?.toString()
            : 0
          : 0
      );
    }
  }, [janusReads]);

  useEffect(() => {
    if (heliosAmount && currentPlsRate) {
      setPlsAmount(
        BigNumber(BigNumber(heliosAmount) * BigNumber(currentPlsRate)).toFixed()
      );
    }
  }, [heliosAmount, currentPlsRate]);

  useEffect(() => {
    if (chainId && chainId !== pulsechain.id) {
      setSwitchChains(true);
    }
  }, [chainId]);

  const heliosAmountMaxButtonCliked = () => {
    if (userBalance !== undefined) {
      setHeliosAmount(etherToFixed(userBalance));
    } else {
      setHeliosAmount(0);
    }
  };

  const switchToPulseChain = async () => {
    switchChain({ chainId: pulsechain.id });
  };

  useEffect(() => {
    if (chainId === pulsechain.id) {
      setSwitchChains(false);
    }
  }, [chainId]);

  const {
    isLoading: approveLoading,
    writeContract: approveWrite,
    error: approveError,
    data: approveHash,
  } = useWriteContract();

  const approveHelios = async () => {
    approveWrite({
      ...heliosContractObj,
      functionName: "approve",
      args: [JanusContractAddress, ethers.constants.MaxUint256],
    });
  };

  const { isSuccess: isApproveConfirmed } = useWaitForTransactionReceipt({
    hash: approveHash,
    chainId: pulsechain.id,
  });

  useEffect(() => {
    if (approveError) {
      toast.error(approveError.message.slice(0, 300) + "...", {
        autoClose: 10000,
      });
      setIsConfirming(false);
    }
  }, [approveError]);

  useEffect(() => {
    if (isApproveConfirmed) {
      toast.success("Approval successful", {
        autoClose: 10000,
      });
      mintJanus();
    }
  }, [isApproveConfirmed]);

  const {
    data: hash,
    writeContract: JanusMint,
    error: writeError,
    isLoading: isMintJanusLoading,
  } = useWriteContract();

  const { isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
    chainId: pulsechain.id,
  });

  useEffect(() => {
    if (writeError) {
      toast.error(writeError.message.slice(0, 300) + "...", {
        autoClose: 10000,
      });
      setIsConfirming(false);
    }
  }, [writeError]);

  const mintJanusCheckApproval = async () => {
    if (heliosAmount > 0 && plsAmount > 0) {
      setIsConfirming(true);
      if (etherToFixed(heliosAllowance) < heliosAmount) {
        approveHelios();
      } else {
        mintJanus();
      }
    } else {
      toast.error("Please Enter Amount To mint", {
        autoClose: 5000,
      });
    }
  };

  const mintJanus = async () => {
    JanusMint({
      ...janusContractObj,
      functionName: "mint",
      args: [address, ethers.utils.parseEther(heliosAmount.toString())],
      value: BigNumber(plsAmount).toFixed(),
    });
  };

  useEffect(() => {
    if (isConfirmed) {
      toast.success("Minted Successfully", {
        autoClose: 5000,
      });
      setIsConfirming(false);
    }
  }, [isConfirmed]);

  const {
    isLoading: wrapLoading,
    writeContract: wrapPls,
    error: wrapError,
    data: wraphash,
  } = useWriteContract();

  const wrapPLS = async () => {
    wrapPls({
      address: selectedToken,
      abi: wplsContract.abi,
      functionName: "deposit",
      args: [],
      value: selectedTokenAmount
        ? ethers.utils.parseEther(
            (selectedTokenAmount - selectedTokenBalance).toString()
          )
        : "0",
    });
  };

  const { isSuccess: isWrapConfirmed } = useWaitForTransactionReceipt({
    hash: wraphash,
    chainId: pulsechain.id,
  });

  useEffect(() => {
    if (wrapError) {
      toast.error(wrapError.message.slice(0, 300) + "...", {
        autoClose: 10000,
      });
      setIsLpConfirming(false);
    }
  }, [wrapError]);

  const getSelectedTokenBalance = async () => {
    const contract = new ethers.Contract(
      selectedToken,
      heliosContract.abi,
      provider
    );
    const balance = await contract.balanceOf(address);
    setSelectedTokenBalance(
      parseFloat(
        selectedTokenSymbol === "WBTC"
          ? ethers.utils.formatUnits(balance, 8)
          : ethers.utils.formatEther(balance)
      )
    );
  };

  useEffect(() => {
    if (isWrapConfirmed) {
      toast.success("Wrapping Token  successful", {
        autoClose: 5000,
      });
      if (selectedToken !== "") {
        getSelectedTokenBalance();
      }
      if (selectedTokenAllowance < selectedTokenAmount) {
        approveLPToken();
      } else {
        mintLpJanus();
      }
    }
  }, [isWrapConfirmed]);

  const {
    isLoading: approveLpLoading,
    writeContract: approveLpWrite,
    error: approveLpError,
    data: approveLpHash,
  } = useWriteContract();

  const approveLPToken = async () => {
    approveLpWrite({
      address: selectedToken,
      abi: janusContract.abi,
      functionName: "approve",
      args: [JanusLpManagerAddress, ethers.constants.MaxUint256],
    });
  };

  const { isSuccess: isLpApproveConfirmed } = useWaitForTransactionReceipt({
    hash: approveLpHash,
    chainId: pulsechain.id,
  });

  useEffect(() => {
    if (approveLpError) {
      toast.error(approveLpError.message.slice(0, 300) + "...", {
        autoClose: 10000,
      });
      setIsLpConfirming(false);
    }
  }, [approveLpError]);

  const mintLpCheckApproval = async () => {
    if (jnsAmount > minimumJnsAmount) {
      if (selectedTokenAmount > 0) {
        setIsLpConfirming(true);
        if (
          selectedTokenSymbol === "WPLS" &&
          selectedTokenAmount > selectedTokenBalance
        ) {
          wrapPLS();
        } else if (selectedTokenAllowance < selectedTokenAmount) {
          approveLPToken();
        } else {
          mintLpJanus();
        }
      } else {
        toast.error("Please Enter Amount To mint", {
          autoClose: 5000,
        });
      }
    } else {
      console.log({ minimumJnsAmount });
      console.log({ jnsAmount });
      toast.error("Less then Minimum deposit", {
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    if (isLpApproveConfirmed) {
      toast.success("Approval  successful", {
        autoClose: 5000,
      });
      mintLpJanus();
    }
  }, [isLpApproveConfirmed]);

  const {
    data: mintLphash,
    writeContract: mintLp,
    error: writeLpError,
    isLoading: isMintLpLoading,
  } = useWriteContract();

  const mintLpJanus = async () => {
    mintLp({
      ...janusLpContractObj,
      functionName: "deposit",
      args: [
        selectedToken,
        selectedTokenSymbol === "WBTC"
          ? ethers.utils.parseUnits(
              selectedTokenAmount
                ? (selectedTokenAmount - 0.00000001).toString()
                : "0",
              8
            )
          : ethers.utils.parseEther(
              selectedTokenAmount
                ? (selectedTokenAmount - 0.000000000001).toString()
                : "0"
            ),
      ],
      chainId: pulsechain.id,
    });
  };

  const { isSuccess: isLpConfirmed } = useWaitForTransactionReceipt({
    hash: mintLphash,
    chainId: pulsechain.id,
  });

  useEffect(() => {
    if (isLpConfirmed) {
      toast.success("Lp Minted Successfully", {
        autoClose: 5000,
      });
      setIsLpConfirming(false);
      setSelectedTokenAmount(0);
    }
    if (selectedToken !== "") {
      getSelectedTokenBalance();
    }
  }, [isLpConfirmed, selectedToken]);

  useEffect(() => {
    if (writeLpError) {
      toast.error(writeLpError.message.slice(0, 300) + "...", {
        autoClose: 10000,
      });
      setIsLpConfirming(false);
    }
  }, [writeLpError]);

  const {
    data: claimLphash,
    writeContract: claimLp,
    error: writeClaimLpError,
    isLoading: isClaimLpLoading,
  } = useWriteContract();

  const { isSuccess: isClaimLpConfirmed } = useWaitForTransactionReceipt({
    hash: claimLphash,
    chainId: pulsechain.id,
  });

  const ClaimLpJanus = async () => {
    setIsClaimLpConfirming(true);
    claimLp({
      ...janusLpContractObj,
      functionName: "claimLP",
      args: [
        selectedTokenPosition,
        selectedTokenStakeIds.findIndex(
          (stakeId) => stakeId.toString() === selectedStakeId
        ),
      ],
    });
  };

  useEffect(() => {
    if (isClaimLpConfirmed) {
      toast.success("Lp Minted Successfully", {
        autoClose: 5000,
      });
      setIsClaimLpConfirming(false);
    }
  }, [isClaimLpConfirmed]);

  useEffect(() => {
    if (writeClaimLpError) {
      toast.error(writeClaimLpError.message.slice(0, 300) + "...", {
        autoClose: 10000,
      });
      setIsClaimLpConfirming(false);
    }
  }, [writeClaimLpError]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
      borderRadius: "8px",
      width: "155px",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px",
      padding: "5px",
      color: "black",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "14px", // Set the font size for the "no options" message
      padding: 5,
    }),
    menu: (provided) => ({
      ...provided,
      width: "155px", // Set the width of the options container popup to 180px
    }),
  };
  const stakeStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "14px",
      borderRadius: "8px",
      width: "205px",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px",
      padding: "5px",
      color: "black",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "14px", // Set the font size for the "no options" message
      padding: 5,
    }),
    menu: (provided) => ({
      ...provided,
      width: "205px", // Set the width of the options container popup to 180px
    }),
  };

  return (
    <JanusStyleWrapper className="janus">
      <div className="calculator_container">
        <h1 style={{ textAlign: "center" }}>Janus Minting</h1>
        {/* left to right */}
        <div className="modal_container">
          <div className="tab_container">
            <div className="v2_footer_logo">
              <img src={HLXLogo} alt="bithu nft logo" />
              <div className="footer_heading">
                <h1>Mint Janus</h1>
                <h1>From Helios</h1>
              </div>
              <img src={Logo} alt="bithu nft logo" />
            </div>
            <div className="tab_content">
              <div className={`content ${getActiveClass(1, "active-content")}`}>
                <div className="miner_setting_group">
                  <h2 className="miner_asset">YOUR PULSECHAIN ASSETS</h2>
                  <div className="miner_asset_main">
                    <div className="miner_asset_container">
                      <p className="miner_asset_left tooltip_parent">
                        Pulse
                        <Tooltip
                          text="This is the amount of PLS in your wallet on Pulsechain"
                          className="custom_tooltip"
                        >
                          <AiOutlineQuestionCircle />
                        </Tooltip>
                      </p>
                      <div className="miner_asset_right">
                        <p>{formatNumber(etherToFixed(plsBalance), 0)} PLS</p>
                        <span>
                          $
                          {formatNumber(
                            parseFloat(etherToFixed(plsBalance)) * pls_to_usd,
                            2
                          )}{" "}
                          USD
                        </span>
                      </div>
                    </div>
                    <div className="miner_asset_container">
                      <p className="miner_asset_left tooltip_parent">
                        Helios
                        <Tooltip
                          text="This is the amount of HLX in your wallet on Pulsechain.  If you don't see a balance, switch to Pulsechain RPC"
                          className="custom_tooltip"
                        >
                          <AiOutlineQuestionCircle />
                        </Tooltip>
                      </p>
                      <div className="miner_asset_right">
                        <p>{formatNumber(etherToFixed(userBalance), 0)} HLX</p>
                        <span>
                          $
                          {formatNumber(
                            parseFloat(etherToFixed(userBalance)) * hlx_to_usd,
                            2
                          )}{" "}
                          USD
                        </span>
                      </div>
                    </div>
                    <div className="miner_asset_container">
                      <p className="miner_asset_left tooltip_parent">
                        Janus
                        <Tooltip
                          text="This is the amount of JNS in your wallet on Pulsechain.  If you don't see a balance, switch to Pulsechain RPC"
                          className="custom_tooltip"
                        >
                          <AiOutlineQuestionCircle />
                        </Tooltip>
                      </p>
                      <div className="miner_asset_right">
                        <p>{formatNumber(etherToFixed(janusBalance), 0)} JNS</p>
                        <span>
                          $
                          {formatNumber(
                            parseFloat(etherToFixed(janusBalance)) * jns_to_usd,
                            2
                          )}{" "}
                          USD
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="miner_hr"></div>
                  <div className="miner_burn_container">
                    <div className="miner_asset_left">
                      <p className="tooltip_parent">
                        Burn Helios
                        <Tooltip
                          text="This is the amount of HLX that will get burned to mint JNS"
                          className="custom_tooltip"
                        >
                          <AiOutlineQuestionCircle />
                        </Tooltip>
                      </p>
                      <button
                        className="max_button"
                        onClick={heliosAmountMaxButtonCliked}
                      >
                        MAX
                      </button>
                    </div>
                    <div className="miner_asset_right">
                      <div className="miner_input_container">
                        <div className="miner_input">
                          <input
                            type="number"
                            className="count_input"
                            value={heliosAmount}
                            onChange={(e) =>
                              onChangeInputHandler(e, "heliosAmount")
                            }
                            //   onKeyDown={(e) => handleKeyDown(e, "minerLength")}
                          />
                        </div>
                        <p>HLX</p>
                      </div>
                      <span>
                        ${formatNumber(hlx_to_usd * heliosAmount, 2)} USD
                      </span>
                    </div>
                  </div>
                  <div className="miner_hr"></div>
                  <div className="miner_protocol_container">
                    <div className="miner_protocol_left">
                      <div style={{ display: "flex", justifyContent: "start" }}>
                        <p
                          className="tooltip_parent"
                          style={{ marginRight: "5px" }}
                        >
                          Protocol Fee
                        </p>
                        <Tooltip
                          text="There is a protocol fee to mint JNS paid in PLS.  This fee goes towards the Helios DAO Treasury and Dev Team"
                          className="custom_tooltip"
                        >
                          <AiOutlineQuestionCircle style={{ opacity: 0.5 }} />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="miner_protocol_right">
                      <div className="miner_input_container">
                        <p>{formatNumber(etherToFixed(plsAmount), 0)} PLS</p>
                      </div>
                      <span>
                        ${formatNumber(etherToFixed(plsAmount) * pls_to_usd, 2)}{" "}
                        USD
                      </span>
                    </div>
                  </div>
                  {/* part */}
                  <div className="miner_cost_container">
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <p
                        className="tooltip_parent"
                        style={{ marginRight: "5px" }}
                      >
                        Total Cost
                      </p>

                      <Tooltip
                        text="This is the total cost to mint JNS.  USD value of HLX burned + USD value of PLS fee."
                        className="custom_tooltip"
                      >
                        <AiOutlineQuestionCircle style={{ opacity: 0.5 }} />
                      </Tooltip>
                    </div>
                    <p>
                      $
                      {formatNumber(
                        etherToFixed(plsAmount) * pls_to_usd +
                          hlx_to_usd * heliosAmount,
                        2
                      )}{" "}
                      USD
                    </p>
                  </div>
                  <div className="miner_roi_container">
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <p
                        className="tooltip_parent"
                        style={{ marginRight: "5px" }}
                      >
                        ROI
                      </p>

                      <Tooltip
                        text="This is the ROI on minting JNS which accounts for 3% JNS tax and the protocol fee.  Formula: ((USD value of JNS minted * 97%) / (USD value of HLX burned + USD value of PLS fee) * 100) - 100."
                        className="custom_tooltip"
                      >
                        <AiOutlineQuestionCircle style={{ opacity: 0.5 }} />
                      </Tooltip>
                    </div>
                    <p>
                      {formatNumber((((jns_to_usd * heliosAmount * 0.97) / (
                        etherToFixed(plsAmount) * pls_to_usd +
                          hlx_to_usd * heliosAmount) * 100) - 100),
                        2
                      )}{" "}
                      %
                    </p>
                  </div>
                  {/* part */}
                  <div className="miner_janus_container">
                    <div className="miner_janus_left">
                      <p className="tooltip_parent">
                        Janus Minted
                        <Tooltip
                          text="This is the amount of JNS you will receive."
                          className="custom_tooltip"
                        >
                          <AiOutlineQuestionCircle />
                        </Tooltip>
                      </p>
                    </div>
                    <div className="miner_janus_right">
                      <div className="miner_input_container">
                        <p>{formatNumber(heliosAmount, 0)} JNS</p>
                      </div>
                      <span>
                        ${formatNumber(jns_to_usd * heliosAmount, 2)} USD
                      </span>
                    </div>
                  </div>
                  {/* part */}
                  {/* <div className="miner_container">
                  <div className="miner_heading">
                    <h6>Send Helios</h6>
                  </div>
                  <div className="miner_input">
                    <input
                      type="number"
                      className="count_input"
                      value={heliosAmount}
                      onChange={(e) => onChangeInputHandler(e, "heliosAmount")}
                      //   onKeyDown={(e) => handleKeyDown(e, "minerLength")}
                    />
                  </div>
                  <div className="miner_balance">
                    <h5>${hlx_to_usd * heliosAmount}</h5>
                    <div className="miner_balance_container">
                      <h5>Balance: {etherToFixed(userBalance)}</h5>
                      <button
                        className="max_button"
                        onClick={heliosAmountMaxButtonCliked}
                      >
                        MAX
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="miner_container"
                  style={{ border: "2px solid rgb(186, 53, 5)" }}
                >
                  <div className="miner_heading">
                    <h6 className="miner_heading_janus">Mint Janus</h6>
                  </div>
                  <div className="miner_input">
                    <input
                      type="number"
                      className="count_input"
                      value={heliosAmount}
                      contentEditable="false"
                    />
                  </div>
                  <div className="miner_balance">
                    <h5>${hlx_to_usd * heliosAmount}</h5>
                    <div className="miner_balance_container">
                      <h5>Balance: {etherToFixed(janusBalance)}</h5>
                    </div>
                  </div>
                </div>
                <div
                  className="miner_container"
                  style={{ border: "2px solid #E5E5E5", opacity: 0.5 }}
                >
                  <div className="miner_protocol">
                    <h3>Protocol Fee:</h3>
                    <h3>{plsAmount}</h3>
                  </div>
                  <div className="miner_protocol">
                    <h4>Value in PLS:</h4>
                    <h4>${0}</h4>
                  </div>
                </div> */}
                  <div className="miner-container">
                    {isConnected ? (
                      <div className="gradient-border">
                        <button
                          className="max_button"
                          onClick={() => {
                            if (switchChains) {
                              switchToPulseChain();
                            } else {
                              mintJanusCheckApproval();
                            }

                            // window.location.href =
                            //   switchChains &&
                            //   "https://bridge.mypinata.cloud/ipfs/bafybeiahfpwhfdjf6iw4mwv5ytj7b6x4nmlxhwyae5ymfjdvszm6q5t3bm/#/bridge";
                          }}
                          disabled={
                            isMintJanusLoading || approveLoading || isConfirming
                          }
                        >
                          {switchChains
                            ? "Switch to Pulse Chain"
                            : "Mint Janus"}
                        </button>
                      </div>
                    ) : (
                      <ConnectButton
                        label="Connect Wallet"
                        chainStatus="none" //icon,name,none
                        showBalance={false} //true,false
                        accountStatus="address" //avatar,address,
                        className="connect_btn"
                      />
                    )}
                  </div>
                </div>
                {/* <div className="miner_setting_group">
                <div className="position_card_name_with_balance">
                  <div className="miner_input_button"></div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
          {/* end */}
          <div className="tab_container">
            <div className="v2_footer_logo">
              <img src={Bridge} alt="bithu nft logo" />
              <div className="footer_heading">
                <h1>Liquidity pool</h1>
                <h1>Promotion</h1>
              </div>
              <img src={Logo} alt="bithu nft logo" />
            </div>
            <div className="tab_content">
              <div className={`content ${getActiveClass(1, "active-content")}`}>
                <div className="miner_setting_group">
                  <div className="miner_asset_container"></div>
                  <div
                    className="minter_droplist"
                    style={{ paddingBottom: "10px" }}
                  >
                    <Select
                      placeholder="Select tokens"
                      styles={customStyles}
                      onChange={async (e) => {
                        const tokenAddress = e.value;
                        const selectedToken = tokens.find(
                          (token) => token.tokenAddress === tokenAddress
                        );
                        setSelectedToken(tokenAddress);
                        setSelectedTokenSymbol(selectedToken.symbol);
                        if (tokenAddress) {
                          const contract = new ethers.Contract(
                            tokenAddress,
                            heliosContract.abi,
                            provider
                          );
                          const balance = await contract.balanceOf(address);
                          console.log(
                            "Selected Token Address:",
                            selectedToken.symbol === "WBTC"
                              ? tokenAddress
                              : "None"
                          );
                          setSelectedTokenBalance(
                            balance
                              ? selectedToken.symbol === "WBTC"
                                ? balance >= 1000
                                  ? parseFloat(
                                      ethers.utils.formatUnits(balance, 8)
                                    )
                                  : 0
                                : balance >= 10 ** 8
                                ? parseFloat(ethers.utils.formatEther(balance))
                                : 0
                              : 0
                          );
                        }
                      }}
                      options={tokens.map((token, index) => {
                        const obj = {
                          label: token.symbol,
                          value: token.tokenAddress,
                        };
                        return obj;
                      })}
                    />

                    <div className="droplist_wrap">
                      <p>
                        {selectedTokenSymbol === "WETH" ||
                        selectedTokenSymbol === "WBTC"
                          ? formatNumber(selectedTokenBalance, 7)
                          : formatNumber(selectedTokenBalance, 3)}{" "}
                        {selectedTokenSymbol ? selectedTokenSymbol : " "}
                      </p>
                      <span>
                        $
                        {formatNumber(
                          parseFloat(selectedTokenBalance) *
                            parseFloat(selectedTokenPrice),
                          2
                        )}{" "}
                        USD
                      </span>
                    </div>
                  </div>
                  <h1 className="minter_droplist_h1">LP TOKENS TO BE MINTED</h1>
                  <div className="minter_division">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4 style={{ width: "90%" }} className="minter_h4">
                        <input
                          type="number"
                          value={
                            selectedTokenAmount === "" ? 0 : selectedTokenAmount
                          }
                          className="minter_count_input"
                          onChange={(e) =>
                            setSelectedTokenAmount(e.target.value)
                          }
                          style={{ marginRight: "10px", width: "100px" }} // Style it as needed
                        />
                        {selectedTokenSymbol ? selectedTokenSymbol : " "} /{" "}
                        {formatNumber(jnsAmount, 0)} JNS
                      </h4>

                      <button
                        className="max_btn"
                        onClick={() =>
                          selectedTokenSymbol === "WPLS"
                            ? setSelectedTokenAmount(
                                parseFloat(
                                  etherToFixed(plsBalance) +
                                    selectedTokenBalance
                                ) - 1000
                              )
                            : setSelectedTokenAmount(selectedTokenBalance)
                        }
                      >
                        MAX
                      </button>
                    </div>
                    <span>
                      ${formatNumber(parseFloat(jnsAmount) * jns_to_usd * 2, 2)}{" "}
                      USD
                    </span>
                  </div>
                  <div className="miner-container">
                    {isConnected ? (
                      <div className="gradient-border">
                        <button
                          className="max_button"
                          onClick={() => {
                            if (switchChains) {
                              switchToPulseChain();
                            } else {
                              mintLpCheckApproval();
                            }

                            // window.location.href =
                            //   switchChains &&
                            //   "https://bridge.mypinata.cloud/ipfs/bafybeiahfpwhfdjf6iw4mwv5ytj7b6x4nmlxhwyae5ymfjdvszm6q5t3bm/#/bridge";
                          }}
                          disabled={
                            isMintLpLoading ||
                            approveLpLoading ||
                            wrapLoading ||
                            isLpConfirming
                          }
                        >
                          {switchChains
                            ? "Switch to Pulse Chain"
                            : "Mint Lp Tokens"}
                        </button>
                      </div>
                    ) : (
                      <ConnectButton
                        label="Connect Wallet"
                        chainStatus="none" //icon,name,none
                        showBalance={false} //true,false
                        accountStatus="address" //avatar,address,
                        className="connect_btn"
                      />
                    )}
                  </div>
                  <div className="miner_hr" style={{ margin: "30px 0" }}></div>
                  {/* blur start */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      gap: "5px",
                    }}
                  >
                    <div className="minter_droplist">
                      <Select
                        placeholder="Select tokens"
                        styles={customStyles}
                        onChange={async (e) => {
                          const tokenAddress = e.value;
                          const selectedToken = tokens.find(
                            (token) => token.tokenAddress === tokenAddress
                          );
                          setSelectedTokenStakes([]);
                          setSelectedStake({});
                          setSelectedTokenPositionData({
                            tokenAmount: 0,
                            jnsAmount: 0,
                          });
                          setDaysPassed(0);
                          setSelectedTokenPositionPercentage(0);
                          setSelectedTokenPosition(tokenAddress);
                          setSelectedTokenSymbolPosition(selectedToken.symbol);
                        }}
                        options={tokens.map((token, index) => {
                          const obj = {
                            label: token.symbol,
                            value: token.tokenAddress,
                          };
                          return obj;
                        })}
                      />
                    </div>
                    {selectedTokenStakes.length > 0 ? (
                      <div className="minter_droplist">
                        <Select
                          placeholder="Select Stake"
                          styles={stakeStyles}
                          onChange={(e) => setSelectedStakeId(e.value)}
                          options={selectedTokenStakes.map((stake, index) => {
                            const obj = {
                              label: new Date(
                                stake.startTime * 1000
                              ).toLocaleString(),
                              value: stake[0],
                            };
                            return obj;
                          })}
                        />
                      </div>
                    ) : (
                      <div className="minter_droplist"></div>
                    )}
                  </div>
                  {selectedTokenStakes.length > 0 ? (
                    <div style={{ position: "relative" }}>
                      <div>
                        {selectedTokenStakes.length > 0 ? (
                          <div
                            className="minter_division"
                            style={{
                              color: "#ffffffcc",
                              padding: "20px 0",
                            }}
                          >
                            <h4>
                              {selectedTokenPositionData.tokenAmount > 0
                                ? selectedTokenSymbolPosition === "WETH"
                                  ? formatNumber(
                                      etherToFixed(
                                        selectedTokenPositionData.tokenAmount
                                      ),
                                      8
                                    )
                                  : selectedTokenSymbolPosition === "WBTC"
                                  ? formatNumber(
                                      etherToFixed8Decimals(
                                        selectedTokenPositionData.tokenAmount
                                      ),
                                      8
                                    )
                                  : formatNumber(
                                      etherToFixed(
                                        selectedTokenPositionData.tokenAmount
                                      ),
                                      1
                                    )
                                : 0}
                              {" " + selectedTokenSymbolPosition}/{" "}
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    etherToFixed(
                                      selectedTokenPositionData.jnsAmount
                                    ),
                                    1
                                  )
                                : 0}{" "}
                              JNS
                            </h4>
                            <h4>
                              {selectedStake.amount
                                ? formatNumber(
                                    etherToFixed(selectedStake.amount),
                                    6
                                  )
                                : 0}{" "}
                              LP
                            </h4>
                            <span>
                              $
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    parseFloat(
                                      etherToFixed(
                                        selectedTokenPositionData.jnsAmount
                                      )
                                    ) *
                                      jns_to_usd *
                                      2,
                                    2
                                  )
                                : 0}{" "}
                              USD
                            </span>
                          </div>
                        ) : (
                          <div
                            className="minter_division"
                            style={{
                              color: "#ffffffcc",
                              padding: "20px 0",
                            }}
                          >
                            <h4>
                              {selectedTokenPositionData.tokenAmount > 0
                                ? selectedTokenSymbolPosition === "WETH"
                                  ? formatNumber(
                                      etherToFixed(
                                        selectedTokenPositionData.tokenAmount
                                      ),
                                      8
                                    )
                                  : selectedTokenSymbolPosition === "WBTC"
                                  ? formatNumber(
                                      etherToFixed8Decimals(
                                        selectedTokenPositionData.tokenAmount
                                      ),
                                      8
                                    )
                                  : formatNumber(
                                      etherToFixed(
                                        selectedTokenPositionData.tokenAmount
                                      ),
                                      1
                                    )
                                : 0}{" "}
                              /{" "}
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    etherToFixed(
                                      selectedTokenPositionData.jnsAmount
                                    ),
                                    1
                                  )
                                : 0}{" "}
                              JNS
                            </h4>
                            <h4>
                              {selectedStake.amount
                                ? formatNumber(
                                    etherToFixed(selectedStake.amount),
                                    6
                                  )
                                : 0}{" "}
                              LP
                            </h4>
                            <span>
                              $
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    parseFloat(
                                      etherToFixed(
                                        selectedTokenPositionData.jnsAmount
                                      )
                                    ) *
                                      jns_to_usd *
                                      2,
                                    2
                                  )
                                : 0}{" "}
                              USD
                            </span>
                          </div>
                        )}
                        {selectedTokenStakes.length > 0 ? (
                          <div className="minter_share">
                            <h3> Your Share</h3>
                            <span>{returnPercentage}%</span>
                          </div>
                        ) : (
                          <div className="minter_share">
                            <h3> Your Share</h3>
                            <span>{returnPercentage}%</span>
                          </div>
                        )}
                        {selectedTokenStakes.length > 0 ? (
                          <div
                            className="minter_division"
                            style={{
                              color: "#ffffffcc",
                              padding: "20px 0",
                            }}
                          >
                            <h4>
                              {selectedTokenPositionData.tokenAmount > 0
                                ? selectedTokenSymbolPosition === "WETH"
                                  ? formatNumber(
                                      (etherToFixed(
                                        selectedTokenPositionData.tokenAmount
                                      ) *
                                        returnPercentage) /
                                        100,
                                      8
                                    )
                                  : selectedTokenSymbolPosition === "WBTC"
                                  ? formatNumber(
                                      (etherToFixed8Decimals(
                                        selectedTokenPositionData.tokenAmount
                                      ) *
                                        returnPercentage) /
                                        100,
                                      8
                                    )
                                  : formatNumber(
                                      (etherToFixed(
                                        selectedTokenPositionData.tokenAmount
                                      ) *
                                        returnPercentage) /
                                        100,
                                      1
                                    )
                                : 0}
                              {" " + selectedTokenSymbolPosition} /{" "}
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    (etherToFixed(
                                      selectedTokenPositionData.jnsAmount
                                    ) *
                                      returnPercentage) /
                                      100,
                                    1
                                  )
                                : 0}{" "}
                              JNS
                            </h4>
                            <h4>
                              {selectedStake.amount
                                ? formatNumber(
                                    (etherToFixed(selectedStake.amount) *
                                      returnPercentage) /
                                      100,
                                    6
                                  )
                                : 0}{" "}
                              LP
                            </h4>
                            <span>
                              $
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    parseFloat(
                                      (etherToFixed(
                                        selectedTokenPositionData.jnsAmount
                                      ) *
                                        returnPercentage) /
                                        100
                                    ) *
                                      jns_to_usd *
                                      2,
                                    2
                                  )
                                : 0}{" "}
                              USD
                            </span>
                          </div>
                        ) : (
                          <div
                            className="minter_division"
                            style={{
                              color: "#ffffffcc",
                              padding: "20px 0",
                            }}
                          >
                            <h4>
                              {selectedTokenPositionData.tokenAmount > 0
                                ? selectedTokenSymbolPosition === "WETH"
                                  ? formatNumber(
                                      (etherToFixed(
                                        selectedTokenPositionData.tokenAmount
                                      ) *
                                        returnPercentage) /
                                        100,
                                      8
                                    )
                                  : selectedTokenSymbolPosition === "WBTC"
                                  ? formatNumber(
                                      (etherToFixed8Decimals(
                                        selectedTokenPositionData.tokenAmount
                                      ) *
                                        returnPercentage) /
                                        100,
                                      5
                                    )
                                  : formatNumber(
                                      (etherToFixed(
                                        selectedTokenPositionData.tokenAmount
                                      ) *
                                        returnPercentage) /
                                        100,
                                      1
                                    )
                                : 0}
                              {" " + selectedTokenSymbolPosition} /{" "}
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    (etherToFixed(
                                      selectedTokenPositionData.jnsAmount
                                    ) *
                                      returnPercentage) /
                                      100,
                                    1
                                  )
                                : 0}{" "}
                              JNS
                            </h4>
                            <h4>
                              {selectedStake.amount
                                ? formatNumber(
                                    (etherToFixed(selectedStake.amount) *
                                      returnPercentage) /
                                      100,
                                    6
                                  )
                                : 0}{" "}
                              LP
                            </h4>
                            <span>
                              $
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    parseFloat(
                                      (etherToFixed(
                                        selectedTokenPositionData.jnsAmount
                                      ) *
                                        returnPercentage) /
                                        100
                                    ) *
                                      jns_to_usd *
                                      2,
                                    2
                                  )
                                : 0}{" "}
                              USD
                            </span>
                          </div>
                        )}
                        {/* part */}
                        {selectedTokenStakes.length > 0 ? (
                          <div className="miner-container">
                            {isConnected ? (
                              <div className="gradient-border">
                                <button
                                  className="max_button"
                                  onClick={() => {
                                    if (switchChains) {
                                      switchToPulseChain();
                                    } else {
                                      ClaimLpJanus();
                                    }
                                  }}
                                  disabled={
                                    isClaimLpLoading || isClaimLpConfirming
                                  }
                                >
                                  {switchChains
                                    ? "Switch to Pulse Chain"
                                    : "Claim Lp Tokens"}
                                </button>
                              </div>
                            ) : (
                              <ConnectButton
                                label="Connect Wallet"
                                chainStatus="none" //icon,name,none
                                showBalance={false} //true,false
                                accountStatus="address" //avatar,address,
                                className="connect_btn"
                              />
                            )}
                          </div>
                        ) : (
                          <div className="miner-container">
                            {isConnected ? (
                              <div className="gradient-border">
                                <button
                                  className="max_button"
                                  onClick={() => {
                                    if (switchChains) {
                                      switchToPulseChain();
                                    } else {
                                      ClaimLpJanus();
                                    }
                                  }}
                                  disabled={
                                    isClaimLpLoading || isClaimLpConfirming
                                  }
                                >
                                  {switchChains
                                    ? "Switch to Pulse Chain"
                                    : "Claim Lp Tokens"}
                                </button>
                              </div>
                            ) : (
                              <ConnectButton
                                label="Connect Wallet"
                                chainStatus="none" //icon,name,none
                                showBalance={false} //true,false
                                accountStatus="address" //avatar,address,
                                className="connect_btn"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div style={{ position: "relative" }}>
                      <span
                        style={{
                          position: "absolute",
                          fontSize: "24px",
                          top: "50%",
                          zIndex: 99,
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          fontFamily: "Cinzel, sans-serif",
                          color: "rgb(244, 185, 64)",
                          textAlign: "center",
                        }}
                      >
                        No User Lp's with selected token
                      </span>
                      <div style={{ filter: "blur(5px)" }}>
                        {selectedTokenStakes.length > 0 ? (
                          <div
                            className="minter_division"
                            style={{
                              color: "#ffffffcc",
                              padding: "20px 0",
                            }}
                          >
                            <h4>
                              {selectedTokenPositionData.tokenAmount > 0
                                ? formatNumber(
                                    etherToFixed(
                                      selectedTokenPositionData.tokenAmount
                                    ),
                                    1
                                  )
                                : 0}
                              {" " + selectedTokenSymbolPosition}/{" "}
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    etherToFixed(
                                      selectedTokenPositionData.jnsAmount
                                    ),
                                    1
                                  )
                                : 0}{" "}
                              JNS
                            </h4>
                            <h4>
                              {selectedStake.amount
                                ? formatNumber(
                                    etherToFixed(selectedStake.amount),
                                    1
                                  )
                                : 0}{" "}
                              LP
                            </h4>
                            <span>
                              $
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    parseFloat(
                                      etherToFixed(
                                        selectedTokenPositionData.jnsAmount
                                      )
                                    ) *
                                      jns_to_usd *
                                      2,
                                    2
                                  )
                                : 0}{" "}
                              USD
                            </span>
                          </div>
                        ) : (
                          <div
                            className="minter_division"
                            style={{
                              color: "#ffffffcc",
                              padding: "20px 0",
                            }}
                          >
                            <h4>
                              {selectedTokenPositionData.tokenAmount > 0
                                ? formatNumber(
                                    etherToFixed(
                                      selectedTokenPositionData.tokenAmount
                                    ),
                                    1
                                  )
                                : 0}
                              {" " + selectedTokenSymbolPosition}/{" "}
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    etherToFixed(
                                      selectedTokenPositionData.jnsAmount
                                    ),
                                    1
                                  )
                                : 0}{" "}
                              JNS
                            </h4>
                            <h4>
                              {selectedStake.amount
                                ? formatNumber(
                                    etherToFixed(selectedStake.amount),
                                    1
                                  )
                                : 0}{" "}
                              LP
                            </h4>
                            <span>
                              $
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    parseFloat(
                                      etherToFixed(
                                        selectedTokenPositionData.jnsAmount
                                      )
                                    ) *
                                      jns_to_usd *
                                      2,
                                    2
                                  )
                                : 0}{" "}
                              USD
                            </span>
                          </div>
                        )}
                        {selectedTokenStakes.length > 0 ? (
                          <div className="minter_share">
                            <h3> Your Share</h3>
                            <span>{returnPercentage}%</span>
                          </div>
                        ) : (
                          <div className="minter_share">
                            <h3> Your Share</h3>
                            <span>{returnPercentage}%</span>
                          </div>
                        )}
                        {selectedTokenStakes.length > 0 ? (
                          <div
                            className="minter_division"
                            style={{
                              color: "#ffffffcc",
                              padding: "20px 0",
                            }}
                          >
                            <h4>
                              {selectedTokenPositionData.tokenAmount > 0
                                ? formatNumber(
                                    (etherToFixed(
                                      selectedTokenPositionData.tokenAmount
                                    ) *
                                      returnPercentage) /
                                      100,
                                    1
                                  )
                                : 0}
                              {" " + selectedTokenSymbolPosition} /{" "}
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    (etherToFixed(
                                      selectedTokenPositionData.jnsAmount
                                    ) *
                                      returnPercentage) /
                                      100,
                                    1
                                  )
                                : 0}{" "}
                              JNS
                            </h4>
                            <h4>
                              {selectedStake.amount
                                ? formatNumber(
                                    (etherToFixed(selectedStake.amount) *
                                      returnPercentage) /
                                      100,
                                    1
                                  )
                                : 0}{" "}
                              LP
                            </h4>
                            <span>
                              $
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    parseFloat(
                                      (etherToFixed(
                                        selectedTokenPositionData.jnsAmount
                                      ) *
                                        returnPercentage) /
                                        100
                                    ) *
                                      jns_to_usd *
                                      2,
                                    2
                                  )
                                : 0}{" "}
                              USD
                            </span>
                          </div>
                        ) : (
                          <div
                            className="minter_division"
                            style={{
                              color: "#ffffffcc",
                              padding: "20px 0",
                            }}
                          >
                            <h4>
                              {selectedTokenPositionData.tokenAmount > 0
                                ? formatNumber(
                                    (etherToFixed(
                                      selectedTokenPositionData.tokenAmount
                                    ) *
                                      returnPercentage) /
                                      100,
                                    1
                                  )
                                : 0}
                              {" " + selectedTokenSymbolPosition} /{" "}
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    (etherToFixed(
                                      selectedTokenPositionData.jnsAmount
                                    ) *
                                      returnPercentage) /
                                      100,
                                    1
                                  )
                                : 0}{" "}
                              JNS
                            </h4>
                            <h4>
                              {selectedStake.amount
                                ? formatNumber(
                                    (etherToFixed(selectedStake.amount) *
                                      returnPercentage) /
                                      100,
                                    1
                                  )
                                : 0}{" "}
                              LP
                            </h4>
                            <span>
                              $
                              {selectedTokenPositionData.jnsAmount > 0
                                ? formatNumber(
                                    parseFloat(
                                      (etherToFixed(
                                        selectedTokenPositionData.jnsAmount
                                      ) *
                                        returnPercentage) /
                                        100
                                    ) *
                                      jns_to_usd *
                                      2,
                                    2
                                  )
                                : 0}{" "}
                              USD
                            </span>
                          </div>
                        )}
                        {/* part */}
                        {selectedTokenStakes.length > 0 ? (
                          <div className="miner-container">
                            {isConnected ? (
                              <div className="gradient-border">
                                <button
                                  className="max_button"
                                  onClick={() => {
                                    if (switchChains) {
                                      switchToPulseChain();
                                    } else {
                                      ClaimLpJanus();
                                    }
                                  }}
                                  disabled={
                                    isClaimLpLoading || isClaimLpConfirming
                                  }
                                >
                                  {switchChains
                                    ? "Switch to Pulse Chain"
                                    : "Claim Lp Tokens"}
                                </button>
                              </div>
                            ) : (
                              <ConnectButton
                                label="Connect Wallet"
                                chainStatus="none" //icon,name,none
                                showBalance={false} //true,false
                                accountStatus="address" //avatar,address,
                                className="connect_btn"
                              />
                            )}
                          </div>
                        ) : (
                          <div className="miner-container">
                            {isConnected ? (
                              <div className="gradient-border">
                                <button
                                  className="max_button"
                                  onClick={() => {
                                    if (switchChains) {
                                      switchToPulseChain();
                                    } else {
                                      ClaimLpJanus();
                                    }
                                  }}
                                  disabled={
                                    isClaimLpLoading || isClaimLpConfirming
                                  }
                                >
                                  {switchChains
                                    ? "Switch to Pulse Chain"
                                    : "Claim Lp Tokens"}
                                </button>
                              </div>
                            ) : (
                              <ConnectButton
                                label="Connect Wallet"
                                chainStatus="none" //icon,name,none
                                showBalance={false} //true,false
                                accountStatus="address" //avatar,address,
                                className="connect_btn"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {/* blur end */}

                {/* <div className="miner_setting_group">
                <div className="position_card_name_with_balance">
                  <div className="miner_input_button"></div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tab_container" style={{ width: "auto", marginTop: 30 }}>
          <div className="tab_info">
            <p>
              Move your funds between Ethereum and PulseChain via the bridge,{" "}
              <a
                target="_blank"
                href="https://bridge.mypinata.cloud/ipfs/bafybeiahfpwhfdjf6iw4mwv5ytj7b6x4nmlxhwyae5ymfjdvszm6q5t3bm/#/bridge"
              >
                here
              </a>
            </p>
            <p>
              For more info on Janus and why it benefits Helios, read the{" "}
              <a
                target="_blank"
                href="https://docs.helios-hlx.win/helios/additional-projects/janus"
              >
                documentation
              </a>
            </p>
          </div>
        </div>
        {/* <div className="social_part">
          <div className="button_functions">
            <button
              onClick={() =>
                window.open(
                  "https://bridge.mypinata.cloud/ipfs/bafybeiahfpwhfdjf6iw4mwv5ytj7b6x4nmlxhwyae5ymfjdvszm6q5t3bm/#/bridge",
                  "_blank"
                )
              }
            >
              PulseChain Bridge
            </button>
          </div>
        </div> */}
      </div>
    </JanusStyleWrapper>
  );
};
export default JanusContent;
