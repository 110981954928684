
import Content from "../components/section/content";

const Dashboard = () => {
  return (
    <>
      <Content />
    </>
  );
};

export default Dashboard;
